import React from "react";
import "../css/LoginComponent.css";
import rectangle from "../images/rectangle.png";
import { API_URL } from "./env";
import { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";

function OtpComponent() {
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { email, username, user } = location.state;

  const resendHandler = async (e) => {
    console.log(email);
    console.log(username);
    console.log(user);
    try {
      const response = await axios.post(
        `${API_URL}/get-otp?email=${email}&username=${username}`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const message = response.data.message;
      alert(message);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/otp-verify`, {
        otp,
        email,
      });
      if (response.data.success) {
        alert("success");
        navigate("/login");
      }
    } catch (error) {
      console.log(error.response);
    }
    // navigate("/login");
  };

  return (
    <div className="container-fluid primary-color">
      <section className=" row">
        {/* left */}
        <div className="vh-100 col-md-6 d-flex justify-content-center align-items-center">
          <div className="shadow p-5 rounded-3 w-50">
            <div className="text-left">
              <h4 className="mb-4">Enter OTP</h4>
              <h6 className="mb-4 text-success">Check your email for OTP</h6>

              {/* OTP INPUT */}
              <div className="otp-container">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input {...props} className="otp-input" />
                  )}
                />
              </div>
              <div>{error ? <p className="text-danger">{error}</p> : null}</div>
              <div className="text-center">
                <button
                  className="button rounded-2 w-50 text-white p-1"
                  onClick={submitHandler}
                >
                  Verify OTP
                </button>
                <div className="p-3">
                  <button onClick={resendHandler}>Resend OTP</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="vh-100 col-md-6 d-flex justify-content-end">
          <div>
            <img
              style={{ height: "100%", width: "100%" }}
              src={rectangle}
              alt="altimage"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default OtpComponent;
