import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import CreatableSelect from "react-select/creatable";
import ReactQuill from "react-quill";
import Select from "react-select";
import currencies from "../json/currency.json";
import educations from "../json/education.json";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { authContext } from "../hooks/authContext";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./env";
import { useLocation } from "react-router-dom";

var query = require("india-pincode-search");

export default function PostJob() {
  const [minDate, setMinDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];

    setMinDate(formattedDate);
  }, []);
  // options
  const jobRoleOptions = [
    { value: "dataAnalyst", label: "Data Analyst" },
    { value: "webDeveloper", label: "Web Developer" },
  ];
  const experienceOptions = [
    { value: "fresher", label: "Fresher" },
    { value: "senior-level", label: "Senior Level" },
  ];
  const jobTypeOptions = [
    { value: "full-time", label: "Full-Time" },
    { value: "part-time", label: "Part-Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Internship" },
    { value: "temporary", label: "Temporary" },
  ];
  const vacanciesOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const [jobTitle, setJobTitle] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [selectededucation, setselectededucation] = useState("");
  var [result, setResult] = useState({ job_desc: "" });
  const [experience, setExperience] = useState("");
  const [expDate, setExpDate] = useState("");
  const [vacancies, setVacancies] = useState("");
  const [pincode, setPincode] = useState("");
  var [city, setCity] = useState("");
  var [state, setState] = useState("");
  var [country, setCountry] = useState("");
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [fullyRemote, setFullyRemote] = useState(false);
  const data = query.search(pincode);
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies.currencies[0].value
  );
  const [benefit, setBenefit] = useState("");
  const { user } = useContext(authContext);
  const usertoken = user.token;
  const navigate = useNavigate();
  const location = useLocation();

  //after enter pincode set value for city,state,country
  if (data.length !== 0) {
    city = data[0].city;
    state = data[0].state;
    country = "INDIA";
  }

  //get keywords from db
  const KeywordgetHandler = async () => {
    try {
      const response = await axios.get(`${API_URL}/keyword/get-keywords`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        const fetchedKeywords = responseData.keywords || []; // Accessing the keywords array from the response
        setKeywords(fetchedKeywords);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };
  useEffect(() => {
    KeywordgetHandler();
  }, [keywords]);

  //store new keyword in db
  const handlekeywordChange = (newKeywords) => {
    // Extracting the values from the selected options
    const selectedValues = newKeywords.map((option) => option.value);
    setSelectedKeywords(selectedValues);

    // Filter out the newly created keywords
    const createdKeywords = newKeywords.filter((keyword) => keyword.__isNew__);
    // Filter out the already existing keywords
    const existingKeywords = createdKeywords.filter((keyword) => {
      return !keywords.some(
        (existingKeyword) => existingKeyword.keyword === keyword.value
      );
    });
    // Send only the last newly created keyword to the backend for storage
    const lastCreatedKeyword = existingKeywords[existingKeywords.length - 1];
    if (lastCreatedKeyword) {
      setKeywords([...keywords, { keyword: lastCreatedKeyword.value }]);
      axios
        .post(
          `${API_URL}/keyword/post-keyword`,
          { keyword: lastCreatedKeyword.value },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${usertoken}`,
            },
          }
        )
        .catch((error) => {
          // Handle error
          console.error("Error creating keyword:", error);
        });
    }
  };

  //generate job description using API
  const jenerateJobDecHandler = async (e) => {
    const stringFromArray = selectedBenefits.join(", ");
    const title = jobTitle;
    const designation = selectedJobType;
    const roles = jobRole;
    const benefits = stringFromArray;
    const education = selectededucation.join(", ");
    const location = city;
    const keywords = selectedKeywords.join(", ");
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3100/generate_job_desc",
        {
          title,
          designation,
          experience,
          roles,
          benefits,
          education,
          location,
          keywords,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      result = response.data;
      setResult(result);
    } catch (error) {
      console.log(error);
    }
  };
  //To store the job Details in db
  const submitPostJobHandler = async (e) => {
    const benefits = selectedBenefits;
    const education = selectededucation;
    const tags = selectedKeywords;
    const minimumSalary = minSalary;
    const maximumSalary = maxSalary;
    const jobBenefits = benefits;
    const jobDescription = result.job_desc;
    const designation = selectedJobType;
    // const cleanedData = jobDescription.replace(/<\/?(p|br)>/g, '');
    const currencyval = selectedCurrency;

    e.preventDefault();

    if (
      jobRole.length <= 0 ||
      experience.length <= 0 ||
      selectedJobType.length <= 0
    ) {
      alert("Fill all Fields");
      return;
    }

    if (minimumSalary > maximumSalary) {
      alert("Invalid Salaries");
      return;
    }
    if (jobDescription.length <= 0) {
      alert("Fill jd");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/job/post_job`,
        {
          jobTitle,
          tags,
          minimumSalary,
          maximumSalary,
          education,
          experience,
          vacancies,
          pincode,
          state,
          country,
          city,
          jobBenefits,
          jobDescription,
          expDate,
          designation,
          jobRole,
          currencyval,
          fullyRemote,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        //console.log('Request was successful!');
        console.log(location);
        console.log(response.data);
        navigate(`/dashboard/jobpreference/${response.data.job._id}`, {
          state: { path: location.pathname },
        });
      } else {
        console.log("Request failed with status code:", response.status);
      }
      result = response.data;

      setResult(result);
    } catch (error) {
      if (error.response) {
      } else if (error.request) {
        console.log(error);
      } else {
        console.log("Error", error);
      }
    }
  };

  const handlefullyremoteChange = () => {
    setFullyRemote(!fullyRemote);
  };
  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);
  };
  const handleJobTypeChange = (selectedOption) => {
    setSelectedJobType(selectedOption ? selectedOption.value : null);
  };
  const handleeducationChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.label);
    setselectededucation(selectedValues);
  };
  const handleJobdecChange = (content) => {
    setResult((prevData) => ({
      ...prevData,
      job_desc: content,
    }));
  };

  const [BUTTONS, setButtons] = useState([
    { id: 0, title: "Distributed Team " },
    { id: 1, title: "Vision Insurance" },
    { id: 2, title: "Medical Insurace" },
    { id: 3, title: "Unlimited Vacations" },
    { id: 4, title: "No whiteboard Interview" },
    { id: 5, title: "Free Gym Membership" },
    { id: 6, title: "Equity Compensation" },
    { id: 7, title: "Pay in crypto" },
    { id: 8, title: "Profit Sharing" },
  ]);

  const handleButton = (id) => {
    const isSelected = selectedBenefits.includes(id);
    const updatedBenefits = isSelected
      ? selectedBenefits.filter((benefit) => benefit !== id)
      : [...selectedBenefits, id];
    setSelectedBenefits(updatedBenefits);
  };
  const handleCustomButton = (value) => {
    const title = value;
    if (value.length <= 0) {
      return;
    }

    setButtons((prev) => [...prev, { id: BUTTONS.length + 1, title: title }]);
  };

  return (
    <div className="container my-4">
      <h2 className="my-3">Post a job</h2>
      <form onSubmit={submitPostJobHandler}>
        <div className="mb-2">
          <label className="my-3">Job Title</label>
          <input
            required={true}
            type="text"
            className="form-control mb-2 p-2"
            placeholder="Add job title, role, vacancies, etc,."
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </div>

        <div className="row">
          <div className="mb-2 col-md-8">
            <label className="my-3">Skills</label>
            <CreatableSelect
              isMulti
              placeholder="Keywords"
              options={keywords.map((keyword) => ({
                value: keyword.keyword,
                label: keyword.keyword,
              }))}
              onChange={handlekeywordChange}
              components={{ DropdownIndicator: null }}
              required
            />
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Job Role</label>
            <Form.Select
              required={true}
              value={jobRole}
              onChange={(e) => setJobRole(e.target.value)}
              className="p-2"
            >
              <option>Select...</option>
              {jobRoleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="mb-2 col-md-6">
            <label className="my-3">Min Salary</label>
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control p-2"
                placeholder="Minimum Salary"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
                required={true}
              />

              <div
                styles={{
                  borderColor: "red",
                }}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={{ value: selectedCurrency, label: selectedCurrency }}
                  onChange={handleCurrencyChange}
                  name="currency"
                  placeholder="INR"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingBottom: "4px",
                      borderLeft: "none",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderColor: "#dee2e6",
                    }),
                  }}
                  options={currencies.currencies}
                />
              </div>
            </div>
          </div>
          <div className="mb-2 col-md-6">
            <label className="my-3">Max Salary</label>
            <div className="input-group">
              <input
                type="number"
                className="form-control p-2"
                placeholder="Maximum Salary"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
                required={true}
              />
              <Select
                value={{ value: selectedCurrency, label: selectedCurrency }}
                isSearchable={true}
                onChange={handleCurrencyChange}
                name="currency"
                placeholder="INR"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,

                    paddingBottom: "4px",
                    borderLeft: "none",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderColor: "#dee2e6",
                  }),
                }}
                options={currencies.currencies}
              />
            </div>
          </div>
        </div>

        <h3>Advance Information</h3>
        <div className="row my-3">
          <div className="mb-2 col-md-4">
            <label className="my-3">Education</label>
            <CreatableSelect
              isMulti
              placeholder="Education"
              openMenuOnFocus={false}
              openMenuOnClick={false}
              options={educations.educations}
              value={educations.educations.find(
                (option) => option.label === selectededucation
              )}
              onChange={handleeducationChange}
              components={{
                DropdownIndicator: null,
              }}
              required={true}
            />
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Experience</label>
            <Form.Select
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              className="p-2"
              required={true}
            >
              <option>Select...</option>
              {experienceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Job Type</label>
            <CreatableSelect
              isClearable
              isSearchable
              options={jobTypeOptions}
              value={jobTypeOptions.find(
                (option) => option.value === selectedJobType
              )}
              onChange={handleJobTypeChange}
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="row my-4">
          <div className="mb-2 col-md-6">
            <label className="my-3">Vacancies</label>
            <input
              type="number"
              value={vacancies}
              onChange={(e) => setVacancies(e.target.value)}
              className="form-control mb-4"
              required={true}
            />
          </div>

          <div className="mb-2 col-md-6">
            <label className="my-3">Expiration Date</label>
            <input
              type="date"
              className="form-control p-2"
              placeholder="DD-MM-YY"
              min={minDate}
              value={expDate}
              onChange={(e) => setExpDate(e.target.value)}
              required={true}
            />
          </div>
        </div>

        {/* Location  */}
        <div
          className="p-4 card container-fluid rounded my-3"
          style={{ backgroundColor: "lightgrey" }}
        >
          <h3 className="mb-3">Location</h3>
          <div className="row">
            <div className=" col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
            </div>

            <div className=" col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="my-1 form-check">
            <input
              className="form-check-input form-check-xl"
              type="checkbox"
              checked={fullyRemote}
              onChange={handlefullyremoteChange}
            />
            Fully Remote Position - <span className="fw-bold">Worldwide</span>
            {/* Other JSX */}
          </div>
        </div>

        <div className="my-4 align-item-center justify-content-center">
          <h3>Job Benefits</h3>
          <div className=" m-1 w-25">
            <input
              type="text"
              placeholder="New Benefit"
              className="px-2"
              style={{
                outline: "none",
                border: "none",
              }}
              onChange={(e) => setBenefit(e.target.value)}
              value={benefit}
            />
            <button
              type="button"
              className="btn btn-primary p-2 rounded-1"
              onClick={() => handleCustomButton(benefit)}
            >
              Add
            </button>
          </div>

          {BUTTONS.map((bt) => (
            <button
              type="button"
              key={bt.id}
              onClick={() => handleButton(bt.title)}
              className={
                selectedBenefits.includes(bt.title)
                  ? "btn btn-outline-primary m-2 text-primary"
                  : "btn btn-outline-secondary m-2"
              }
            >
              {bt.title}
            </button>
          ))}
        </div>

        <div>
          <div className="d-flex align-items-center">
            <h3 className="mx-0">Job Description</h3>
            <span className="text-danger fs-2 mx-0">
              <sup>*</sup>
            </span>
          </div>

          <div>
            <ReactQuill
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["link"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              value={result.job_desc}
              onChange={handleJobdecChange}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              placeholder="Type / Paste Job Description or Generate Job Description..."
              className="my-3 border-top"
            />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center ">
          <button
            className="btn btn-primary rounded-1 w-25"
            type="button"
            onClick={jenerateJobDecHandler}
          >
            <span>Generate Job Description</span>
          </button>

          <button type="submit" className="btn btn-primary rounded-1 w-25">
            <span>Post Job</span>
          </button>
        </div>
      </form>
    </div>
  );
}
