import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/EmployerProfile.css";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authContext } from "../hooks/authContext";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";
import { AiFillFileImage } from "react-icons/ai";
import { AiOutlineLink } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import axios from "axios";
import { API_URL } from "./env";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const CustomTab = (props) => {
  return (
    <Tab
      {...props}
      sx={{
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center",
        "& ion-icon": {
          fontSize: "20px",
          marginBottom: "-5px",
          marginRight: "5px",
          fontWeight: "bold",
        },
        "& div": {
          fontWeight: "bold",
        },
        "&:not(.Mui-selected)": {
          color: "grey",
        },

        ...(props.sx || {}),
      }}
    >
      {props.label}
    </Tab>
  );
};

export default function EmployerProfile() {
  const { user } = useContext(authContext);
  const userId = user.id;
  const usertoken = user.token;

  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    address: "",
    email: "",
    aboutUs: "",
    phoneNumber: "",
    vision: "",
    establishYear: "",
    instagram: "",
  });

  const [logo, setLogo] = useState(null);
  const [ba, setBa] = useState(null);

  const tab3getHandler = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        console.log("Request was successful!");
        const userData = response.data;
        setUserData({
          companyName: userData.user.companyName,
          address: userData.user.address,
          email: userData.user.email,
          aboutUs: userData.user.aboutUs,
          phoneNumber: userData.user.phoneNumber,
          orgType: userData.user.orgType,
          industry: userData.user.industry,
          teamSize: userData.user.teamSize,
          establishYear: userData.user.establishYear,
          website: userData.user.website,
          vision: userData.user.vision,
          instagram: userData.user.instagram,
          facebook: userData.user.facebook,
          twitter: userData.user.twitter,
          youtube: userData.user.youtube,
          linkedin: userData.user.linkedin,
          pincode: userData.user.pincode,
          city: userData.user.city,
          state: userData.user.state,
          country: userData.user.country,
          logoImage: userData.user.logoImage,
          bannerImage: userData.user.bannerImage,
        });

        setLogo(userData.user.logoImage);
        setBa(userData.user.bannerImage);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };
  useEffect(() => {
    tab3getHandler();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleQuillChange = (content) => {
    setUserData((prevData) => ({
      ...prevData,
      aboutUs: content,
    }));
  };
  const handleQuillChange2 = (content) => {
    setUserData((prevData) => ({
      ...prevData,

      vision: content,
    }));
  };
  const [value, setValue] = React.useState(0);

  const [logoImage, setLogoImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [logoFileName, setLogoFileName] = useState("No selected file");
  const [bannerFileName, setBannerFileName] = useState("No selected file");
  const [imageUrl, setImageUrl] = useState("");

  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const handleLogoImageUpload = (files) => {
    setLogoFileName(files[0].name);
    setLogoImage(files[0]);
    const url = URL.createObjectURL(files[0]);
    setImageUrl(url);
  };

  const handleBannerImageUpload = (files) => {
    setBannerFileName(files[0].name);
    setBannerImage(files[0]);
    const url = URL.createObjectURL(files[0]);
    setBannerImageUrl(url);
  };
  const options = [
    {
      value: "linkedin",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ion-icon name="logo-linkedin" style={{ color: "blue" }}></ion-icon>
          &nbsp;LinkedIn
        </div>
      ),
    },

    {
      value: "instagram",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ion-icon name="logo-instagram" style={{ color: "blue" }}></ion-icon>
          &nbsp;Instagram
        </div>
      ),
    },
    {
      value: "twitter",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ion-icon name="logo-twitter" style={{ color: "blue" }}></ion-icon>
          &nbsp;Twitter
        </div>
      ),
    },

    {
      value: "youtube",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ion-icon name="logo-youtube" style={{ color: "blue" }}></ion-icon>
          &nbsp;Youtube
        </div>
      ),
    },
    {
      value: "facebook",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ion-icon name="logo-facebook" style={{ color: "blue" }}></ion-icon>
          &nbsp;Facebook
        </div>
      ),
    },
  ];
  const [inputFields, setInputFields] = useState([]);
  useEffect(() => {
    if (userData) {
      const initialInputFields = [
        { selectValue: options[0], textValue: userData.linkedin || "" },
        { selectValue: options[1], textValue: userData.instagram || "" },
        { selectValue: options[2], textValue: userData.twitter || "" },
        { selectValue: options[3], textValue: userData.youtube || "" },
        { selectValue: options[4], textValue: userData.facebook || "" },
      ];
      setInputFields(initialInputFields);
    }
  }, [userData]);

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { selectValue: options[0], textValue: "" },
    ]);
  };

  const handleSelectChange = (index, selectedOption) => {
    const values = [...inputFields];
    values[index].selectValue = selectedOption;
    setInputFields(values);
  };

  const handleTextChange = (index, event) => {
    const values = [...inputFields];
    values[index].textValue = event.target.value;
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handlePrevious = () => {
    setValue((prevValue) => Math.max(prevValue - 1, 0));
  };

  const navigate = useNavigate();
  const tab3submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${API_URL}/users_update/${userId}`,
        {
          address: userData.address,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          pincode: userData.pincode,
          city: userData.city,
          state: userData.state,
          country: userData.country,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        navigate("/dashboard/my_jobs");
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };

  const tab0submitHandler = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", logoImage);
      formData.append("image", bannerImage);
      formData.append("companyName", userData.companyName);
      formData.append("aboutUs", userData.aboutUs);
      setLoading(true);
      const response = await axios.put(
        `${API_URL}/users_update/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        setValue((prevValue) => prevValue + 1);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };

  const tab1submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${API_URL}/users_update/${userId}`,
        {
          orgType: userData.orgType,
          industry: userData.industry,
          teamSize: userData.teamSize,
          establishYear: userData.establishYear,
          website: userData.website,
          vision: userData.vision,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setValue((prevValue) => prevValue + 1);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };

  const tab2submitHandler = async (event) => {
    event.preventDefault();
    try {
      const dataToSend = JSON.stringify(inputFields, (key, value) => {
        if (key === "selectValue" && typeof value === "object") {
          return value.value;
        }
        return value;
      });

      const parsedData = JSON.parse(dataToSend);
      // Make a POST request to the server with the parsed data
      const response = await axios.put(
        `${API_URL}/users_update_social_link/${userId}`,
        { data: parsedData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      setValue((prevValue) => prevValue + 1);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          sx={{ marginTop: "-1%" }}
        >
          <CustomTab
            label={
              <div>
                <ion-icon name="person-outline"></ion-icon>
                Company Info
              </div>
            }
          />
          <CustomTab
            label={
              <div>
                <ion-icon name="person-circle-outline"></ion-icon>
                Founding Info
              </div>
            }
          />
          <CustomTab
            label={
              <div>
                <ion-icon name="globe-outline"></ion-icon>
                Social Network Profile
              </div>
            }
          />
          <CustomTab
            label={
              <div>
                <ion-icon name="at-outline"></ion-icon>
                Contact
              </div>
            }
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="container mt-2">
          <form onSubmit={tab0submitHandler}>
            <div className="mb-3">
              <label
                htmlFor="companyName"
                className="form-label"
                style={{ display: "flex", alignItems: "center" }}
              >
                Company name{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              </label>
              <input
                className="form-control small-options"
                id="companyName"
                name="companyName"
                value={userData.companyName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="aboutUs" className="form-label">
                About Us
              </label>
              <div></div>

              <ReactQuill
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["link"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                className="quill-editor my-2 border-top"
                placeholder="Write down about your company here. Let the candidate know who we are..."
                id="aboutUs"
                name="aboutUs"
                type="text"
                value={userData.aboutUs}
                onChange={handleQuillChange}
              />
            </div>

            <div className="mt-2">
              <br></br>
              <h6>
                <b>Logo & Banner Image</b>
              </h6>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 mt-2">
                  Upload Logo
                  <div className="mt-1 image_upload text-center p-4 d-flex align-items-center">
                    <div
                      onClick={() =>
                        document.querySelector(".logo-input-field").click()
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        name="logoImage"
                        className="logo-input-field"
                        hidden
                        onChange={({ target: { files } }) =>
                          handleLogoImageUpload(files)
                        }
                      />

                      {imageUrl || logo ? (
                        <img
                          src={imageUrl || logo}
                          width={500}
                          height={500}
                          alt={logoFileName || "logo"}
                        />
                      ) : (
                        <>
                          <MdCloudUpload
                            color="#1475cf"
                            size={40}
                            style={{ marginLeft: "40%" }}
                          />
                          <p className="mt-3" style={{ fontSize: "0.875rem" }}>
                            <b>Browse photo </b>or drop here
                          </p>
                          <p
                            className="text-muted"
                            style={{ fontSize: "0.7rem", marginTop: "-10px" }}
                          >
                            A photo larger than 400 pixels work best. Max photo
                            size 5MB.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <section className="uploaded-row">
                    <AiFillFileImage color="#1475cf" />
                    <span className="upload-content">
                      {logoFileName} -
                      <MdDelete
                        onClick={() => {
                          setLogoFileName("No selected File");
                          setLogoImage(null);
                        }}
                      />
                    </span>
                  </section>
                </div>

                <div className="col-9 mt-2">
                  Banner Image
                  <div className=" mt-1 banner_upload">
                    <div
                      onClick={() =>
                        document.querySelector(".banner-input-field").click()
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="banner-input-field"
                        name="bannerImage"
                        hidden
                        onChange={({ target: { files } }) =>
                          handleBannerImageUpload(files)
                        }
                      />

                      {bannerImageUrl || ba ? (
                        <img
                          src={bannerImageUrl || ba}
                          width={400}
                          height={200}
                          alt={bannerFileName || "banner"}
                          className="img-center"
                        />
                      ) : (
                        <>
                          <MdCloudUpload
                            color="#1475cf"
                            size={40}
                            style={{ marginLeft: "40%" }}
                          />
                          <p className="mt-3" style={{ fontSize: "0.875rem" }}>
                            <b>Browse photo </b>or drop here
                          </p>
                          <p
                            className="text-muted"
                            style={{ fontSize: "0.7rem", marginTop: "-10px" }}
                          >
                            Banner images optical dimension 1520x400. Supported
                            <br></br> format JPEG,PNG.Max photo size is 5MB.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <section className="uploaded-row">
                    <AiFillFileImage color="#1475cf" />
                    <span className="upload-content">
                      {bannerFileName} -
                      <MdDelete
                        onClick={() => {
                          setBannerFileName("No selected File");
                          setBannerImage(null);
                        }}
                      />
                    </span>
                  </section>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-5 px-4 py-2 text-sm"
            >
              {loading ? "Saving..." : "Save & Next"}
              <BiChevronRight style={{ display: "inline-block" }} size={25} />
            </button>
          </form>
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <div className="container mt-2">
          <form onSubmit={tab1submitHandler}>
            {/* Founding Info Section */}
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="organizationType" className="form-label">
                    Organization Type
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select small-options"
                    id="orgType"
                    name="orgType"
                    value={userData.orgType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="industryType" className="form-label">
                    Industry Types
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select small-options"
                    id="industry"
                    name="industry"
                    value={userData.industry}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="teamSize" className="form-label">
                    Team Size
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <select
                    className="form-select small-options"
                    id="teamSize"
                    name="teamSize"
                    value={userData.teamSize}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Year of Establishment
                  </label>
                  <input
                    type="date"
                    style={{ width: "102%" }}
                    className="form-control small-options"
                    id="establishYear"
                    name="establishYear"
                    value={userData.establishYear}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-4 mb-3 mx-2">
                  <label htmlFor="companyWebsite" className="form-label">
                    Company Website
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </label>
                  <div className="input-group" style={{ width: "103%" }}>
                    <input
                      type="text"
                      className="form-control small-options"
                      id="website"
                      name="website"
                      value={userData.website}
                      onChange={handleInputChange}
                      placeholder="Website url..."
                    />
                    <span className="input-group-text">
                      <AiOutlineLink className="text-primary" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="companyvision" className="form-label">
                  Description
                </label>
                <ReactQuill
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["link"],
                      [{ list: "ordered" }, { list: "bullet" }],
                    ],
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: "200px",
                  }}
                  className="quill-editor my-2 border-top"
                  placeholder="Tell us about your company vision..."
                  id="vision"
                  name="vision"
                  value={userData.vision}
                  onChange={handleQuillChange2}
                />
              </div>
            </div>
            <button
              type="submit"
              onClick={handlePrevious}
              className="btn mt-4 px-4 py-2 me-2 prevbutton"
            >
              Previous
            </button>
            <button type="submit" className="btn btn-primary mt-4 px-4 py-2">
              Save & Next
              <BiChevronRight style={{ display: "inline-block" }} size={25} />
            </button>
          </form>
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <div>
          {inputFields.map((inputField, index) => (
            <div key={index}>
              <div className="row my-3">
                <div className="col-md-3">
                  <Select
                    value={inputField.selectValue}
                    onChange={(selectedOption) =>
                      handleSelectChange(index, selectedOption)
                    }
                    options={options}
                  />
                </div>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Profile link/url..."
                    value={inputField.textValue}
                    onChange={(event) => handleTextChange(index, event)}
                    // onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-1">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => handleRemoveFields(index)}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      style={{ fontSize: "24px" }}
                    ></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          ))}
          <br></br>
          <button
            type="button"
            className="btn btn-light col-md-12"
            onClick={handleAddFields}
          >
            <ion-icon name="add-circle-outline"></ion-icon>
            &nbsp;Add New Social Link
          </button>
        </div>
        <br></br>
        <button
          type="submit"
          onClick={handlePrevious}
          className="btn mt-4 px-4 py-2 me-2 prevbutton"
        >
          Previous
        </button>
        <button
          type="submit"
          onClick={tab2submitHandler}
          className="btn btn-primary mt-4 px-4 py-2"
        >
          Save & Next
          <BiChevronRight style={{ display: "inline-block" }} size={25} />
        </button>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <form onSubmit={tab3submitHandler}>
          <div className="mb-3">
            <label htmlFor="address" className="form-label">
              Map Location
            </label>
            <input
              className="form-control"
              id="address"
              name="address"
              value={userData.address}
              onChange={handleInputChange}
            />
            <div className="row mt-3">
              <div className=" col-6">
                <input
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  placeholder="pincode"
                  value={userData.pincode}
                  onChange={handleInputChange}
                />
              </div>{" "}
              <div className=" col-6">
                <input
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder="city"
                  value={userData.city}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-6">
                <input
                  className="form-control"
                  id="state"
                  name="state"
                  placeholder="state"
                  value={userData.state}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" col-6">
                <input
                  className="form-control"
                  id="country"
                  name="country"
                  placeholder="country"
                  value={userData.country}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="phoneNumber"
              className="form-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              Phone <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            </label>
            <div className="rounded">
              <PhoneInput
                inputProps={{ name: "phone", required: true }}
                className="input-group mb-3 phone"
                inputStyle={{ borderRadius: "5px" }}
                buttonStyle={{ borderRadius: "5px", backgroundColor: "white" }}
                country="in"
                value={userData.phoneNumber}
                onChange={(phoneNumber) =>
                  setUserData({ ...userData, phoneNumber })
                }
                placeholder="Phone Number.."
              />
            </div>
          </div>

          <div className="mb-3">
            <label
              htmlFor="companyEmail"
              className="form-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              Email <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            </label>
            <div
              className="input-group mb-3"
              style={{ marginLeft: "-1.5%", width: "101.5%" }}
            >
              <span className="input-group-text">
                <ion-icon
                  name="mail-open-outline"
                  style={{ color: "blue" }}
                ></ion-icon>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Email Address"
                id="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button
            onClick={handlePrevious}
            className="btn mt-4 px-4 py-2 me-2 prevbutton"
          >
            Previous
          </button>
          <button type="submit" className="btn btn-primary mt-4 px-4 py-2">
            Finish Editing
            <BiChevronRight style={{ display: "inline-block" }} size={25} />
          </button>
        </form>
      </CustomTabPanel>
    </Box>
  );
}
