import { useParams } from "react-router-dom";
import { authContext } from "../hooks/authContext";
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/JobDetails.css";
import { Link } from "react-router-dom";
import { API_URL } from "./env";

export default function JobDetails() {
  const [jobData, setJobData] = useState({
    tags: [],
    education: [],
    jobBenefits: [],
  });
  const { _id } = useParams();
  const { user } = useContext(authContext);
  const usertoken = user ? user.token : null;
  useEffect(() => {
    if (usertoken) {
      //get jobdetails from db
      const getJobDetailsHandler = async () => {
        try {
          const response = await axios.get(`${API_URL}/job/get-job/${_id}`, {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          });
          if (response.status >= 200 && response.status < 300) {
            setJobData(response.data);
          } else {
            console.log("Request failed with status code:", response.status);
          }
        } catch (error) {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Error Response Data:", error.response.data);
            console.error("Error Status Code:", error.response.status);
            console.error("Error Headers:", error.response.headers);
          }
        }
      };

      getJobDetailsHandler();
    }
  }, [usertoken, _id]);

  const handleButton = (title) => {
    // Handle button click action
  };
  const formattedDate = new Date(jobData.expDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <div className="container my-3">
      {" "}
      <div className="row">
        <div className="col-6">
          <h2 className="my-3">
            Job Details
            <Link
              to={`/dashboard/job-update/${jobData._id}`}
              className="btn btn-outline-primary m-3 my-1 fw-bold"
              type="button"
            >
              Edit
            </Link>
          </h2>
        </div>
      </div>
      <form>
        <div className="mb-2">
          <label className="my-2">Job Title :</label>
          <span>{jobData.jobTitle}</span>
        </div>
        <div className="row my-2">
          <div className="mb-2 col-md-4">
            <label>Keywords :</label>
            <span>{jobData.tags.join(", ")}</span>
          </div>
          <div className="mb-2 col-md-4">
            <label>Job Role :</label>
            <span>{jobData.jobRole}</span>
          </div>
        </div>
        <h3>Salary</h3>
        <div className="row">
          <div className="mb-2 col-md-4">
            <label className="my-3">Min Salary :</label>
            <span>
              {jobData.minimumSalary} {jobData.currencyval}
            </span>
          </div>
          <div className="mb-2 col-md-6">
            <label className="my-3">Max Salary :</label>
            <span>
              {jobData.maximumSalary} {jobData.currencyval}
            </span>
          </div>
        </div>
        <h3>Advance Information</h3>
        <div className="row my-3">
          <div className="mb-2 col-md-4 my-3">
            <label>Education :</label>
            <span>{jobData.education.join(" ,")}</span>
          </div>
          <div className="mb-2 col-md-3">
            <label className="my-3">Experience :</label>
            <span>{jobData.experience}</span>
          </div>
          <div className="mb-2 col-md-4">
            <label className="my-3">Job Type :</label>
            <span>{jobData.designation}</span>
          </div>
        </div>
        <div className="row">
          <div className="mb-2 col-md-4">
            <label className="my-3">Vacancies : </label>
            <span>{jobData.vacancies}</span>
          </div>
          <div className="mb-2 col-md-6">
            <label className="my-3">Expiration Date : </label>
            <span>{formattedDate}</span>
          </div>
        </div>
        {/* Location  */}
        <div
          className="p-4 card container-fluid rounded my-3"
          style={{ backgroundColor: "lightgrey" }}
        >
          <h3>Location</h3>
          <div className="row">
            <div className="col-md-5">
              <label className="my-2">Pincode :</label>
              <span>{jobData.pincode}</span>
            </div>
            <div className="col-md-6">
              <label className="my-2">City : </label>
              <span>{jobData.city}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <label className="my-2">State :</label>
              <span>{jobData.state}</span>
            </div>
            <div className="col-md-6">
              <label className="my-2">Country : </label>
              <span>{jobData.country}</span>
            </div>
          </div>
          <div className="my-3">
            <label> Fully Remote Position :</label>
            <span style={{ marginLeft: "0%" }}>
              {" "}
              {jobData.fullyRemote === "true" ? "Yes" : "No"}
            </span>
          </div>
        </div>
        <div className="my-4 align-item-center justify-content-center">
          <h3>Job Benefits</h3>
          {jobData.jobBenefits.length === 0 ? (
            <p>No Benefits listed</p>
          ) : (
            jobData.jobBenefits.map((jobBenefits, index) => (
              <button
                key={index}
                onClick={() => handleButton(jobBenefits)}
                className={
                  jobData.jobBenefits.includes(jobBenefits)
                    ? "btn btn-outline-primary m-2"
                    : "btn btn-outline-secondary m-2"
                }
              >
                {jobBenefits}
              </button>
            ))
          )}
        </div>
        <div>
          <div className="d-flex align-items-center">
            <h3>Job Description</h3>
          </div>
          <div>
            <ReactQuill
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["link"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              value={jobData.jobDescription}
              readOnly={true}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              className="my-3 border-top"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
