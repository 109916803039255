import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import style from "../css/table-row.module.css";
import { Link, useLocation } from "react-router-dom";
import UploadModal from "./UploadModal";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { API_URL } from "./env";
import { authContext } from "../hooks/authContext";

function ProfileMenu({ id }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [count, setCount] = useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user } = useContext(authContext);
  const usertoken = user ? user.token : null;
  const getCandidatesCount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/candidate/get-candidates-count/${id}`,
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      setCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCandidatesCount();
  }, []);

  return (
    <>
      <button
        className="btn btn-outline-primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <b>Profiles ({count})</b>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Link
            className="text-decoration-none"
            to="/dashboard/my_jobs/candidates_list/screening"
            state={{ jobId: id }}
          >
            Screening
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className="text-decoration-none"
            to="/dashboard/my_jobs/candidates_list/interview"
            state={{ jobId: id }}
          >
            Interview
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}

function DummyTable({ tableData, expireFunction }) {
  const location = useLocation();

  const columns = [
    {
      name: "JOBS",
      selector: (row) => {
        const expDate = new Date(row.expDate);
        const differenceInMilliseconds = expDate - new Date();
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return (
          <div className="py-3">
            <div>
              <strong className="text-bold">{row.jobTitle}</strong>
            </div>
            <div className={style.subtext}>
              {row.work} {differenceInDays < 0 ? "0" : differenceInDays} days
              remaining
            </div>
          </div>
        );
      },
    },
    {
      name: "STATUS",
      selector: (row) => {
        const expDate = new Date(row.expDate);
        const differenceInMilliseconds = expDate - new Date();
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return (
          <div className="d-flex pt-2">
            <div>
              {differenceInDays > 0 ? (
                <ion-icon
                  name="checkmark-done-circle-outline"
                  color="success"
                ></ion-icon>
              ) : (
                <ion-icon
                  name="close-circle-outline"
                  color="danger"
                  size="medium"
                ></ion-icon>
              )}
            </div>
            <div
              className={`pt-1 pl-2 ${
                differenceInDays > 0 ? "text-success" : "text-danger"
              }`}
            >
              {differenceInDays > 0 ? "Active" : "Expired"}
            </div>
          </div>
        );
      },
    },
    {
      name: "APPLICATIONS",
      selector: (row) => {
        return (
          <div className="d-flex pt-2">
            <div className="px-2">
              <ion-icon name="people-outline"></ion-icon>
            </div>
            <div className="text-secondary pt-1">
              {" "}
              {row.applications} Applications
            </div>
          </div>
        );
      },
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const expDate = new Date(row.expDate);
        const differenceInMilliseconds = expDate - new Date();
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );
        const jobName = row.jobTitle;

        return (
          <div className="d-flex">
            <div>
              <ProfileMenu id={row._id} />
            </div>
            <UploadModal jobId={row._id} jobName={row.jobTitle} />
            <div className="dropend z-3 position-static">
              <button
                className={`${style.dotbutton} m-1`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
              </button>
              <ul className="dropdown-menu p-2">
                <li className="d-flex pt-2">
                  <Link className="btn btn-light pr-2 pl-5" type="button">
                    {" "}
                    <span>
                      <ion-icon
                        name="add-circle-outline"
                        style={{ fontSize: "17px" }}
                      ></ion-icon>{" "}
                    </span>
                    Promote Job
                  </Link>
                </li>
                <li className="d-flex pt-2">
                  <Link
                    to={`/dashboard/job-get/${row._id}`}
                    className="btn btn-light"
                    type="button"
                  >
                    <span>
                      <ion-icon
                        name="eye-outline"
                        style={{ fontSize: "17px" }}
                      ></ion-icon>
                    </span>{" "}
                    View Detail
                  </Link>
                </li>

                {differenceInDays > 0 && (
                  <li className="d-flex pt-2">
                    <Link
                      className="btn btn-light "
                      type="button"
                      onClick={() => {
                        expireFunction(row);
                      }}
                    >
                      {" "}
                      <span>
                        <ion-icon
                          name="close-circle-outline"
                          style={{ fontSize: "17px" }}
                        ></ion-icon>{" "}
                      </span>
                      Make it Expire
                    </Link>
                  </li>
                )}
                <li className="d-flex pt-2">
                  <Link
                    to={`/dashboard/jobpreference/${row._id}`}
                    state={{ path: location.pathname }}
                    className="btn btn-light"
                    type="button"
                  >
                    {" "}
                    <span>
                      <ion-icon
                        name="add-circle-outline"
                        style={{ fontSize: "17px" }}
                      ></ion-icon>{" "}
                    </span>
                    Add / Edit Preferences{""}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        );
      },
    },
  ];
  // const handleViewDetail = (jobId) => {
  //   console.log(jobId);
  // };

  const customStyles = {
    table: {
      style: {
        minHeight: "15rem",
      },
    },
    rows: {
      // style: {
      //   padding: "0.5rem",
      // },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "rgb(189, 189, 189)",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  return (
    <DataTable
      columns={columns}
      data={tableData}
      customStyles={customStyles}
      pagination
      noDataComponent={
        <div>
          You don't have any jobs posted{" "}
          <Link to="/dashboard/postajob">Post Job</Link>
        </div>
      }
    />
  );
}
export default DummyTable;
