import * as React from "react";

import Menu from "@mui/material/Menu";

import UnscheduledMenuItems from "./UnscheduledMenuItems";
import ScheduledMenuItems from "./ScheduledMenuItems";
import RejectedMenuItems from "./RejectedMenuItems";
import SelectedMenuItems from "./SelectedMenuItems";
import ScreenSelectMenuItems from "./resume-screening-tab/ScreenSelectMenuItems";
import ReviewTabMenuItems from "./resume-screening-tab/ReviewTabMenuItems";
import ScreenRejectMenuItems from "./resume-screening-tab/ScreenRejectMenuItems";

export default function ActionButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        className="btn btn-outline-primary"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Actions
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.name === "unscheduled" && <UnscheduledMenuItems {...props} />}
        {props.name === "scheduled" && <ScheduledMenuItems {...props} />}
        {props.name === "reject" && <RejectedMenuItems {...props} />}
        {props.name === "select" && <SelectedMenuItems {...props} />}
        {props.name === "screenselect" && <ScreenSelectMenuItems {...props} />}
        {props.name === "review" && <ReviewTabMenuItems {...props} />}
        {props.name === "screenreject" && <ScreenRejectMenuItems {...props} />}
      </Menu>
    </div>
  );
}
