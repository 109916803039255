import React, { useContext, useState } from "react";
import { IconButton, Badge, Menu, MenuItem } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationContext } from "../context/NotificationContext";

const Notification = () => {
  const { notifications, removeNotification } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleOpen}>
        <Badge badgeContent={notifications.length} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {notifications.length === 0 ? (
          <MenuItem onClick={handleClose}>No new notifications</MenuItem>
        ) : (
          notifications?.map((notification) => (
            <MenuItem
              key={notification.id}
              className={notification.status ? "bg-success" : "bg-danger"}
              onClick={() => removeNotification(notification.id)}
            >
              {notification.message}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default Notification;
