import { useParams } from "react-router-dom";
import { authContext } from "../hooks/authContext";
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import Form from "react-bootstrap/Form";
import CreatableSelect from "react-select/creatable";
import currencies from "../json/currency.json";
import educations from "../json/education.json";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { API_URL } from "./env";
import { useNavigate } from "react-router-dom";

export default function JobDetails() {
  const [minDate, setMinDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];

    setMinDate(formattedDate);
  }, []);
  const [selectedKeywordsValues, setSelectedKeywordsValues] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [experience, setExperience] = useState("");
  const [currencyval, setCurrencyval] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectededucation, setselectededucation] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [expDate, setExpDate] = useState("");
  const [vacancies, setVacancies] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const navigate = useNavigate();
  const [jobBenefits, setJobBenefits] = useState([]);
  const [benefit, setBenefit] = useState("");

  const { _id } = useParams();
  const { user } = useContext(authContext);
  const usertoken = user ? user.token : null;
  const [jobData, setJobData] = useState({
    tags: [],
    education: [],
    jobBenefits: [],
    jobTitle: "",
    maximumSalary: "",
    city: "",
    jobRole: "",
    experience: "",
    country: "",
    currencyval: "",
    jobDescription: "",
  });
  const jobRoleOptions = [
    { value: "dataAnalyst", label: "Data Analyst" },
    { value: "webDeveloper", label: "Web Developer" },
  ];
  const experienceOptions = [
    { value: "fresher", label: "Fresher" },
    { value: "senior-level", label: "Senior Level" },
  ];
  const jobTypeOptions = [
    { value: "full-time", label: "Full-Time" },
    { value: "part-time", label: "Part-Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Internship" },
    { value: "temporary", label: "Temporary" },
  ];
  //get keywords from db
  const KeywordgetHandler = async () => {
    try {
      const response = await axios.get(`${API_URL}/keyword/get-keywords`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        const fetchedKeywords = responseData.keywords || []; // Accessing the keywords array from the response
        setKeywords(fetchedKeywords);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };
  useEffect(() => {
    KeywordgetHandler();
  }, []);

  // Function to fetch job details
  useEffect(() => {
    if (usertoken) {
      const getJobDetailsHandler = async () => {
        try {
          const response = await axios.get(`${API_URL}/job/get-job/${_id}`, {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          });
          if (response.status >= 200 && response.status < 300) {
            setJobData(response.data);
          } else {
            console.log("Request failed with status code:", response.status);
          }
        } catch (error) {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Error Response Data:", error.response.data);
            console.error("Error Status Code:", error.response.status);
            console.error("Error Headers:", error.response.headers);
          }
        }
      };
      getJobDetailsHandler();
    }
  }, [usertoken, _id]);

  // Populate state variables with fetched data
  useEffect(() => {
    const selectedOptions = jobData.tags.map((tag) => ({
      value: tag,
      label: tag,
    }));
    const education = jobData.education.map((education) => ({
      value: education,
      label: education,
    }));
    setselectededucation(education);
    setSelectedKeywordsValues(selectedOptions);
    setJobTitle(jobData.jobTitle);
    setMinSalary(jobData.minimumSalary);
    setMaxSalary(jobData.maximumSalary);
    setCity(jobData.city);
    setJobRole(jobData.jobRole);
    setPincode(jobData.pincode);
    setState(jobData.state);
    setExperience(jobData.experience);
    setCountry(jobData.country);
    setCurrencyval(jobData.currencyval);
    setJobDescription(jobData.jobDescription);
    setSelectedJobType(jobData.designation);
    setVacancies(jobData.vacancies);
    setJobBenefits(jobData.jobBenefits)

    // Format expiration date
    let formattedDate = "Date not available";
    if (jobData.expDate) {
      const originalDate = new Date(jobData.expDate);
      if (!isNaN(originalDate.getTime())) {
        formattedDate = originalDate.toISOString().split("T")[0];
      }
    }
    setExpDate(formattedDate);

    // Set selected currency
    const initialCurrency = currencies.currencies.find(
      (currency) => currency.value === currencyval
    );
    if (initialCurrency) {
      setSelectedCurrency(initialCurrency);
    }
  }, [jobData, currencyval]);

  // Handle form submission
  const SumbitUpdateJobDetails = async (e) => {
    e.preventDefault();
    try {
      if (minSalary > maxSalary) {
        alert("Invalid Salaries");
        return;
      }
      const response = await axios.put(
        `${API_URL}/job/edit-job/${_id}`,
        {
          jobTitle,
          tags: selectedKeywordsValues.map((valueObj) => valueObj.value),
          jobRole,
          minimumSalary: minSalary,
          maximumSalary: maxSalary,
          currencyval: selectedCurrency.value,
          education: selectededucation.map((valueObj) => valueObj.label),
          experience,
          designation: selectedJobType,
          vacancies,
          expDate,
          pincode,
          city,
          state,
          country,
          fullyRemote: jobData.fullyRemote,
          jobDescription,
          jobBenefits: jobBenefits.map((benefit) => benefit.value || benefit),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        //
        navigate(`/dashboard/job-get/${_id}`);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };

  // Handlers for form inputs

  const handleCustomButton = (value) => {
    if (value.length === 0) {
      return;
    }
    setJobBenefits((prevBenefits) => [...prevBenefits, value]);
    setBenefit("");
  };

  useEffect(() => {
    console.log('Updated jobBenefits:', jobBenefits);
  }, [jobBenefits]);

  const handleOnChange = (selectedOptions) => {
    setSelectedKeywordsValues(selectedOptions);
  };

  const handleeducationOnChange = (education) => {
    setselectededucation(education);
  };

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };

  const handleMinSalaryChange = (e) => {
    setMinSalary(e.target.value);
  };

  const handleMaxSalaryChange = (e) => {
    setMaxSalary(e.target.value);
  };

  const handleJobRoleChange = (e) => {
    setJobRole(e.target.value);
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const handleJobDescriptionChange = (value) => {
    setJobDescription(value);
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };

  const handleJobTypeChange = (selectedOption) => {
    setSelectedJobType(selectedOption ? selectedOption.value : null);
  };

  const currencyvalue = selectedCurrency ? selectedCurrency.value : "None";

  return (
    <div className="container my-3">
      <h2 className="my-3">Job Details</h2>
      <form onSubmit={SumbitUpdateJobDetails}>
        <div className="mb-2">
          <label className="my-3">Job Title</label>
          <input
            type="text"
            className="form-control mb-2 p-2"
            placeholder="Add job title, role, vacancies, etc,."
            value={jobTitle}
            onChange={handleJobTitleChange}
            required={true}
          />
        </div>

        <div className="row">
          <div className="mb-2 col-md-8">
            <label className="my-3">Keywords</label>
            <CreatableSelect
              isMulti
              placeholder="Keywords"
              openMenuOnFocus={false}
              openMenuOnClick={false}
              options={keywords.map((keyword) => ({
                value: keyword.keyword,
                label: keyword.keyword,
              }))}
              components={{
                DropdownIndicator: null,
              }}
              value={selectedKeywordsValues}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Job Role</label>
            <Form.Select
              value={jobRole}
              className="p-2"
              onChange={handleJobRoleChange}
              required={true}
            >
              <option>Select...</option>
              {jobRoleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="mb-2 col-md-6">
            <label className="my-3">Min Salary</label>
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control p-2"
                placeholder="Minimum Salary"
                value={minSalary}
                onChange={handleMinSalaryChange}
                required={true}
              />
              <div
                styles={{
                  borderColor: "red",
                }}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={selectedCurrency ? selectedCurrency.value : null} // Use selectedCurrency.value as the value
                  onChange={handleCurrencyChange}
                  name="currency"
                  placeholder={currencyvalue}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingBottom: "4px",
                      borderLeft: "none",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderColor: "#dee2e6",
                    }),
                  }}
                  options={currencies.currencies}
                />
              </div>
            </div>
          </div>
          <div className="mb-2 col-md-6">
            <label className="my-3">Max Salary</label>
            <div className="input-group">
              <input
                type="number"
                className="form-control p-2"
                placeholder="Maximum Salary"
                value={maxSalary}
                onChange={handleMaxSalaryChange}
                required={true}
              />
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                value={selectedCurrency ? selectedCurrency.value : null} // Use selectedCurrency.value as the value
                onChange={handleCurrencyChange}
                name="currency"
                placeholder={currencyvalue}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingBottom: "4px",
                    borderLeft: "none",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderColor: "#dee2e6",
                  }),
                }}
                options={currencies.currencies}
              />
            </div>
          </div>
        </div>
        <h3>Advance Information</h3>
        <div className="row my-3">
          <div className="mb-2 col-md-4">
            <label className="my-3">Education</label>
            <CreatableSelect
              isMulti
              placeholder="Education"
              openMenuOnFocus={false}
              openMenuOnClick={false}
              options={educations.educations}
              value={selectededucation}
              onChange={handleeducationOnChange}
              components={{
                DropdownIndicator: null,
              }}
              required={true}
            />
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Experience</label>
            <Form.Select
              placeholder={experience}
              value={experience}
              className="p-2"
              onChange={handleExperienceChange}
              required={true}
            >
              <option>Select...</option>
              {experienceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="mb-2 col-md-4">
            <label className="my-3">Job Type</label>
            <CreatableSelect
              placeholder={selectedJobType}
              value={jobTypeOptions.find(
                (option) => option.value === selectedJobType
              )}
              options={jobTypeOptions}
              onChange={handleJobTypeChange}
            />
          </div>
        </div>
        {/* Row 2 */}
        <div className="row my-4">
          <div className="mb-2 col-md-6">
            <label className="my-3">Vacancies</label>
            <input
              required
              type="number"
              value={vacancies}
              onChange={(e) => setVacancies(e.target.value)}
              className="form-control mb-4"
            />
          </div>

          <div className="mb-2 col-md-6">
            <label className="my-3">Expiration Date</label>
            <input
              type="date"
              className="form-control p-2"
              placeholder="DD-MM-YY"
              min={minDate}
              value={expDate}
              onChange={(e) => setExpDate(e.target.value)}
              required
            />
          </div>
        </div>
        {/* Location  */}
        <div
          className="p-4 card container-fluid rounded my-3"
          style={{ backgroundColor: "lightgrey" }}
        >
          <h3>Location</h3>
          <div className="row">
            <div className=" col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
            </div>

            <div className=" col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="my-3 form-check">
            <input
              className="form-check-input form-check-xl"
              type="checkbox"
              checked={jobData.fullyRemote === "true"}
              onChange={(e) => {
                const newValue = e.target.checked ? "true" : "false";
                setJobData({ ...jobData, fullyRemote: newValue });
              }}
            />
            Fully Remote Position - <span className="fw-bold">Worldwide</span>
          </div>
        </div>

        <div className="my-4 align-item-center justify-content-center">
          <h3>Job Benefits</h3>
          <div className=" m-1 w-25">
            <input
              type="text"
              placeholder="New Benefit"
              className="px-2"
              style={{
                outline: "none",
                border: "none",
              }}
              onChange={(e) => setBenefit(e.target.value)}
              value={benefit}
            />
            <button
              type="button"
              className="btn btn-primary p-2 rounded-1"
              onClick={() => {
                handleCustomButton(benefit)
                setBenefit("");

              }
              }
            >
              Add
            </button>
          </div>


          {jobBenefits.map((benefit, index) => (
            <button
              key={index}
              className={
                jobBenefits.includes(benefit)
                  ? "btn btn-outline-primary m-2 "
                  : "btn btn-outline-secondary m-2"
              }
            >
              {benefit}
            </button>
          ))}
        </div>

        <div>
          <div className="d-flex align-items-center">
            <h3>Job Description</h3>
          </div>
          <div>
            <ReactQuill
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  ["link"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              value={jobDescription}
              onChange={handleJobDescriptionChange}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              className="my-3 border-top"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center" >

          <button type="submit" className="btn btn-primary rounded-1 mx-2">
            <span>Update</span>
          </button>

        </div>
      </form>
    </div>
  );
}
