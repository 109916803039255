import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import ResultBarChart from "./ResultBarChart";

const style = {
  position: "absolute",
  top: "0.5%",
  left: "25%",
  width: "60%",

  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 1,
};

export default function ViewInterviewDetails({ details }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const score = details.reason.interview.score;
  const reasons = details.reason;

  const interviewReason = reasons.interview.reason;

  const { ["Interview Feedback"]: _, ...newObject } = interviewReason;

  return (
    <div>
      <button className="btn text-dark" onClick={handleOpen}>
        <b>View Details</b>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex my-1">
            <h5>Candidate Name : </h5>
            <h5 className="mx-1">
              {details.resume.full_name[0].toLowerCase()}
            </h5>
          </div>
          <div className="d-flex my-1">
            <h5>Role Applied : </h5>
            <h5 className="mx-1">{details.jobTitle}</h5>
          </div>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 1, md: 2 }}
          >
            <Gauge
              width={120}
              height={120}
              value={score}
              startAngle={-110}
              endAngle={110}
              valueMax={100}
              valueMin={0}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 14,
                  fontWeight: "bold",
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: score > 80 ? "#52b202" : score <= 50 ? "red" : "orange",
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`}
            />
          </Stack>

          <h5 className="text-center">Overall Resume Score</h5>
          <h4>Score Distribution</h4>
          <div>
            <ResultBarChart dataset={newObject} />
          </div>
          <div>
            <h4>Interview Feedback</h4>
            <p>{reasons.interview.reason["Interview Feedback"]}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
