import { createContext, useReducer, useEffect } from "react";

export const authContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
    case "LOGIN":
      return {
        user: action.payload,
        
      };

    case "LOGOUT":
      return {
        user: null,
        
      };

      
  }
};

export const AuthContextprovider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
 

    if (user) {
      dispatch({ type: "LOGIN", payload: user });
    }
   
  }, []);

  return (
    <authContext.Provider value={{ ...state, dispatch }}>
      {children}
    </authContext.Provider>
  );
};
