import React, { useContext } from "react";
import { NavLink, Outlet } from "react-router-dom";
// import { SidebarData } from './SidebarData';
import "../css/LeftNavbar.css";
import { IconContext } from "react-icons";
import Home from "./HomeComponent";
import * as FaIcons from "react-icons/fa";
// import * as AiIcons from 'react-icons/ai';
import * as IoIcons from "react-icons/io";
import { authContext } from "../hooks/authContext";

const Leftside = ({ user }) => (
  <div>
    <IconContext.Provider value={{ color: "#fff" }}>
      <nav className="nav-menu active">
        <ul className="nav-menu-items">
          <b className="mx-4">EMPLOYERS DASHBOARD</b>

          <li className="nav-text">
            <NavLink to="/dashboard/my_jobs" className="nav-link active">
              <i>
                <IoIcons.IoMdFiling style={{ color: "black" }} />
              </i>
              <span className="span-style">My Jobs</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink to="/dashboard/postajob" className="nav-link active">
              <i>
                <FaIcons.FaPlus style={{ color: "black" }} />
              </i>
              <span className="span-style">Post a Job</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink
              to="/dashboard/employers_profile"
              className="nav-link active"
            >
              <i>
                <IoIcons.IoMdPerson style={{ color: "black" }} />
              </i>
              <span className="span-style">Employers Profile</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink to="/dashboard/plans_billing" className="nav-link active">
              <i>
                <FaIcons.FaCreditCard style={{ color: "black" }} />
              </i>
              <span className="span-style">Plans & Billing</span>
            </NavLink>
          </li>

          {user.user.userRole === "admin" && (
            <li className="nav-text">
              <NavLink to="/dashboard/all-company" className="nav-link active">
                <i>
                  <IoIcons.IoMdPeople style={{ color: "black" }} />
                </i>
                <span className="span-style">All Companies</span>
              </NavLink>
            </li>
          )}

          <li className="nav-text">
            <NavLink to="/settings" className="nav-link active">
              <i>
                <IoIcons.IoMdSettings style={{ color: "black" }} />
              </i>
              <span className="span-style">Settings</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </IconContext.Provider>
  </div>
);

function LeftNavbar() {
  const { user } = useContext(authContext);
  return (
    <>
      <Home />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Leftside user={user} />
            <div
              className="position-fixed border-start border-2"
              style={{
                color: "grey",
                height: "100%",
                marginLeft: "23%",
                marginTop: "-27.5%",
              }}
            ></div>
          </div>
          <div className="col-md-9">
            <main>
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftNavbar;
