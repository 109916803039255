import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../hooks/authContext";
import "../css/JobPreference.css";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { API_URL } from "./env";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import InterviewRoundSelection from "./InterviewRoundSelection";

const JobPreference = () => {
  const { user } = useContext(authContext);
  const userId = user.id;
  const usertoken = user.token;
  const { _id } = useParams();
  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState("");
  const [newTagPercentage, setNewTagPercentage] = useState(0); // New state for tag percentage
  const [newSubTagName, setNewSubTagName] = useState({});
  const [newSubTagPercentage, setNewSubTagPercentage] = useState(0);
  const [subkeywordsOptions, setSubKeywordOptions] = useState([]);
  const [subTagAdded, setSubTagAdded] = useState(false);
  const [editingSubTagIndex, setEditingSubTagIndex] = useState(null);
  const [editingSubTagName, setEditingSubTagName] = useState({});
  const [editingSubTagPercentage, setEditingSubTagPercentage] = useState(0);
  const [showAddSubTagInput, setShowAddSubTagInput] = useState({}); // State to control displaying the input fields for adding a new sub-tag for each tag
  const jobId = _id;
  const [jobData, setJobData] = useState({});
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const path = location.state.path;
  const navigate = useNavigate();

  const [keywords, setKeywords] = useState([]);

  // Fetch tags from backend when the component mounts
  useEffect(() => {
    axios
      .get(`${API_URL}/api/get-sub-keywords`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
      .then((res) => {
        setSubKeywordOptions(res.data.subKeywords);
      });

    fetchTagsFromBackend()
      .then((data) => setTags(data))
      .catch((error) => console.error("Error fetching tags:", error));
  }, []);

  //get keywords from db
  const KeywordgetHandler = async () => {
    try {
      const response = await axios.get(`${API_URL}/keyword/get-keywords`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        const fetchedKeywords = responseData.keywords || []; // Accessing the keywords array from the response
        setKeywords(fetchedKeywords);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };
  useEffect(() => {
    KeywordgetHandler();
  }, [keywords]);

  useEffect(() => {
    if (usertoken) {
      //get jobdetails from db
      const getJobDetailsHandler = async () => {
        try {
          const response = await axios.get(`${API_URL}/job/get-job/${_id}`, {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          });
          if (response.status >= 200 && response.status < 300) {
            setJobData(response.data);
          } else {
            console.log("Request failed with status code:", response.status);
          }
        } catch (error) {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Error Response Data:", error.response.data);
            console.error("Error Status Code:", error.response.status);
            console.error("Error Headers:", error.response.headers);
          }
        }
      };

      getJobDetailsHandler();
    }
  }, []);

  const fetchTagsFromBackend = async () => {
    const response = await axios.get(
      `${API_URL}/job/get-keyword-by-jobid/${_id}`,
      {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      }
    );
    if (response.data.tags) {
      const res = response.data.tags.map((tag) => {
        return {
          name: tag,
          percentage: 0, // Include percentage data from backend
          subTags: [],
        };
      });
      return res;
    } else {
      const res = response.data.map((tag) => {
        return {
          name: tag.name,
          percentage: tag.percentage,
          subTags: tag.subKeywords,
        };
      });
      return res;
    }
  };

  const handleSavePreference = async () => {
    const totalTagsPercentage = tags.reduce(
      (acc, curr) => acc + curr.percentage,
      0
    );
    console.log(tags);

    tags.forEach((tag) => {
      let sum = 0;
      tag.subTags.forEach((subTag) => {
        sum += subTag.percentage;
      });

      if (sum > 100) {
        setIsError(true);
      }

      sum = 0;
    });
    if (totalTagsPercentage != 100) {
      alert("Total Keyword percentage must be 100");
      return;
    }

    if (isError) {
      console.log("Error detected");
      return;
    }
    setIsError(false);

    console.log("db saved");
    try {
      const response = await axios.post(
        `${API_URL}/api/preferences`,
        {
          tags,
          jobId,
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      navigate("/dashboard/my_jobs");
    } catch (error) {
      console.error("Error saving preference:", error);
      alert("Error saving preference. Please try again later.");
    }
  };

  const handleAddTag = () => {
    if (newTagName) {
      const newTag = {
        name: newTagName,
        percentage: newTagPercentage,
        subTags: [],
      }; // Include percentage for new tag
      setTags([...tags, newTag]);
      setNewTagName("");
      setNewTagPercentage(0); // Reset tag percentage after adding
    } else {
      alert("Please enter a valid keyword name");
    }
  };

  const handleDeleteTag = (tagIndex) => {
    const newTags = [...tags];
    newTags.splice(tagIndex, 1);
    setTags(newTags);
  };

  const handleAddSubTag = (tagIndex) => {
    const newTags = [...tags];
    const tag = newTags[tagIndex];
    const totalSubTagsPercentage = tag.subTags.reduce(
      (acc, curr) => acc + curr.percentage,
      0
    );
    if (newSubTagName.value === undefined) return;
    const subTagName = newSubTagName.value.trim();
    const subTagPercentage = parseInt(newSubTagPercentage);
    if (
      subTagName &&
      subTagPercentage > 0 &&
      subTagPercentage <= 100 &&
      totalSubTagsPercentage + subTagPercentage <= 100
    ) {
      const newSubTag = {
        name: subTagName,
        percentage: subTagPercentage,
        isNew: newSubTagName.__isNew__ ? true : false,
      };
      tag.subTags.push(newSubTag);
      setTags(newTags);
      setNewSubTagName({});
      setNewSubTagPercentage(0);
      setSubTagAdded(true); // Set the subTagAdded state to true after adding the sub-tag
      setShowAddSubTagInput((prevState) => ({
        ...prevState,
        [tagIndex]: false,
      })); // Hide the input fields after adding the sub-tag
    } else {
      alert(
        "Please enter a valid sub-keyword name and percentage (1-100) that doesn't exceed the remaining percentage"
      );
    }
  };

  const handleRemoveTag = (tagIndex, subTagIndex) => {
    const newTags = [...tags];
    if (subTagIndex === undefined) {
      newTags.splice(tagIndex, 1);
    } else {
      newTags[tagIndex].subTags.splice(subTagIndex, 1);
    }
    setTags(newTags);
  };

  const handlePercentageChange = (tagIndex, subIndex, percentage) => {
    console.log(eval(percentage));

    percentage = eval(percentage) !== undefined && parseInt(percentage);

    const newTags = [...tags];

    if (subIndex === undefined) {
      newTags[tagIndex].percentage = percentage;
    } else {
      newTags[tagIndex].subTags[subIndex].percentage = percentage;
    }

    let sum = 0;
    newTags[tagIndex].subTags.forEach((tag) => {
      sum += tag.percentage;
    });

    console.log(sum);

    if (sum > 100) {
      setError("Percentage exceeded 100");
      setIsError(true);
      return;
    }

    if (subIndex !== undefined && percentage > 100) {
      alert("Sub-Keyword percentage cannot exceed 100%");
      return;
    }
    setTags(newTags);
    setIsError(false);
  };
  return (
    <div>
      <InterviewRoundSelection jobId={jobId} />
      <h3 className="page-title">
        Choose Interview Preference - {jobData.jobTitle}
      </h3>

      <div className="job-preference-container">
        {tags.map((tag, tagIndex) => (
          <div key={tagIndex} className="tag-item">
            <div className="d-flex">
              {/* className='page-title' */}
              <h4 className="page-title">{tag.name.toUpperCase()}</h4>
              <div className="tag-percentage">
                <input
                  type="number"
                  value={tag.percentage}
                  placeholder="Percentage"
                  onChange={(e) => {
                    const newTags = [...tags];
                    newTags[tagIndex].percentage = parseInt(e.target.value);
                    setTags(newTags);
                  }}
                  min="1"
                  max="100"
                  className="input-tag form-control mx-4 w-75 text-primary"
                />
              </div>
              {/* options */}
              <div>
                <button onClick={() => handleDeleteTag(tagIndex)}>
                  <IonIcon color="danger" icon={trashOutline} />
                </button>
                {tag.subTags.length == 0 && (
                  <button
                    onClick={() =>
                      setShowAddSubTagInput((prevState) => ({
                        ...prevState,
                        [tagIndex]: !prevState[tagIndex],
                      }))
                    }
                  >
                    {showAddSubTagInput[tagIndex] ? (
                      <ion-icon
                        name="remove-circle-outline"
                        color="danger"
                      ></ion-icon>
                    ) : (
                      <ion-icon
                        name="add-circle-outline"
                        color="success"
                      ></ion-icon>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex w-100">
              <ul className="sub-tag-list text-right">
                {tag.subTags.map((subTag, subIndex) => (
                  <li key={subIndex} className="sub-tag-item">
                    {editingSubTagIndex &&
                    editingSubTagIndex.tagIndex === tagIndex &&
                    editingSubTagIndex.subIndex === subIndex ? (
                      <>
                        <div className="row">
                          <div className="col-md-3 mx-4 text-left">
                            <input
                              type="text"
                              value={editingSubTagName}
                              disabled
                              className="input-tag"
                            />
                          </div>

                          <div className="col-md-3 text-center">
                            <input
                              type="number"
                              value={editingSubTagPercentage}
                              onChange={(e) =>
                                setEditingSubTagPercentage(
                                  parseInt(e.target.value)
                                )
                              }
                              min="1"
                              max="100"
                              className="input-tag text-primary"
                            />
                          </div>
                        </div>
                        {/* <button onClick={handleUpdateSubTag} className="blue-button">
                      <IonIcon icon={createOutline} />
                    </button> */}
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-3 mx-4 text-left">
                            {subTag.name.toUpperCase()}
                          </div>

                          <div className="col-md-3 text-center">
                            <input
                              type="number"
                              value={subTag.percentage}
                              onChange={(e) =>
                                handlePercentageChange(
                                  tagIndex,
                                  subIndex,
                                  e.target.value
                                )
                              }
                              min="0"
                              max="100"
                              //disabled={subTagAdded}
                              className="input-tag text-primary"
                              style={{
                                marginRight: "0px",
                                paddingRight: "0px",
                                textAlign: "center",
                              }}
                            />
                          </div>

                          <div className="col-md-4 text-left ">
                            <button
                              onClick={() =>
                                handleRemoveTag(tagIndex, subIndex)
                              }
                            >
                              <IonIcon color="danger" icon={trashOutline} />
                            </button>
                            {subIndex === tag.subTags.length - 1 ? (
                              <button
                                onClick={() =>
                                  setShowAddSubTagInput((prevState) => ({
                                    ...prevState,
                                    [tagIndex]: !prevState[tagIndex],
                                  }))
                                }
                              >
                                {showAddSubTagInput[tagIndex] ? (
                                  <ion-icon
                                    name="remove-circle-outline"
                                    color="danger"
                                  ></ion-icon>
                                ) : (
                                  <ion-icon
                                    name="add-circle-outline"
                                    color="success"
                                  ></ion-icon>
                                )}
                              </button>
                            ) : null}
                            {/* <button onClick={() => handleEditSubTag(tagIndex, subIndex, subTag.name, subTag.percentage)} className="blue-button">
                      <IonIcon icon={createOutline} />
                    </button> */}
                          </div>
                        </div>
                      </>
                    )}
                  </li>
                ))}
                {showAddSubTagInput[tagIndex] && (
                  <li className="sub-tag-item">
                    <div className="row">
                      <div className="col-md-4 mx-3 text-left">
                        <CreatableSelect
                          value={{ label: newSubTagName.value }}
                          className="creatable-select-tag"
                          //value={newSubTagName.value}
                          options={subkeywordsOptions}
                          onChange={(value) => {
                            console.log(value);
                            setNewSubTagName(value);
                          }}
                        />
                      </div>

                      <div className="col-md-3 text-center">
                        <input
                          type="number"
                          placeholder="Enter percentage"
                          value={newSubTagPercentage}
                          onChange={(e) =>
                            setNewSubTagPercentage(parseInt(e.target.value))
                          }
                          min="1"
                          className="input-tag text-primary w-75"
                          style={{
                            marginRight: "0px",
                            padding: "0px",
                            textAlign: "center",
                            height: "90%",
                          }}
                        />
                      </div>

                      <div className="col-md-3 text-left">
                        <button onClick={() => handleAddSubTag(tagIndex)}>
                          <ion-icon
                            name="add-circle-outline"
                            color="success"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        ))}

        <div className="tag-item">
          <h4>
            <label>Add New Keyword</label>
          </h4>
          <div>
            <div className="row">
              <div className="col-md-4 text-left">
                <CreatableSelect
                  value={{ label: newTagName }}
                  placeholder="Keywords"
                  className="creatable-select-tag"
                  options={keywords.map((keyword) => ({
                    value: keyword.keyword,
                    label: keyword.keyword,
                  }))}
                  onChange={(value) => {
                    setNewTagName(value.value);
                    console.log(value);
                  }}
                />
              </div>

              <div className="col-md-3 text-center">
                <input
                  type="number"
                  value={newTagPercentage}
                  placeholder="Percentage"
                  onChange={(e) =>
                    setNewTagPercentage(parseInt(e.target.value))
                  }
                  min="1"
                  max="100"
                  className="input-tag form-control"
                />
              </div>
              <div className="col-md-3 text-left">
                <button onClick={handleAddTag}>
                  <ion-icon
                    name="add-circle-outline"
                    color="success"
                  ></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="text-danger">{isError ? <p>*{error}</p> : null}</div>

        {/* Save Preference Button */}

        <div className="d-flex justify-content-between save-preference-button ">
          {path.includes("/dashboard/postajob") ? (
            <button
              className="save-button blue-btn"
              onClick={() => {
                navigate("/dashboard/my_jobs");
              }}
            >
              Skip for Now
            </button>
          ) : (
            <button
              className="save-button blue-btn"
              onClick={() => {
                navigate("/dashboard/my_jobs");
              }}
            >
              Back to My Jobs
            </button>
          )}

          <button
            disabled={isError}
            onClick={handleSavePreference}
            style={{
              cursor: isError && "not-allowed",
            }}
            className="save-button blue-btn"
          >
            Save Preference
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPreference;
