import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import { authContext } from "../../hooks/authContext";
import { API_URL } from "../env";
import axios from "axios";

function ScreenSelectMenuItems(props) {
  const { user } = useContext(authContext);
  const handleCandidate = async (status) => {
    try {
      const results = await axios.put(
        `${API_URL}/candidate/change-candidate/${props.id}?status=${status}&stage=screening`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(results.data.message);

      console.log(status);
      console.log(props.id);
      props.getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MenuItem onClick={() => {}}>{props.downLoadCv}</MenuItem>
      <MenuItem onClick={() => handleCandidate("reviewed")}>
        {props.review}
      </MenuItem>
      <MenuItem onClick={() => handleCandidate("rejected")}>
        {props.reject}
      </MenuItem>
    </>
  );
}

export default ScreenSelectMenuItems;
