import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { ImFolderDownload } from "react-icons/im";
import ActionButton from "./ActionButton";
import { API_URL } from "./env";
import axios from "axios";
import { authContext } from "../hooks/authContext";
function Scheduled({ jobId }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectHandlerFunction = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const handleDropCandidates = () => {
    const id = selectedRows.map((obj) => obj.id);
    console.log(id);
  };
  const [tableData, setTableData] = useState([]);

  const { user } = useContext(authContext);
  const getData = async () => {
    try {
      const results = await axios.get(
        `${API_URL}/candidate/get-candidates/${jobId}?status=scheduled&stage=interview`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setTableData(results.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "Candidate Name",
      selector: (row) => {
        return (
          <h6 className="text-bold text-primary text-wrap">
            {row.resume.full_name[0].toLowerCase()}
          </h6>
        );
      },
    },
    {
      name: "Candidate Title",
      selector: (row) => {
        return <h6 className="text-primary text-wrap">{row.jobTitle}</h6>;
      },
    },

    {
      name: "Experience",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {" "}
            {row.resume.total_experience ? row.resume.total_experience : 0}
          </h6>
        );
      },
    },

    {
      name: "Scheduled Date",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {row.interviewScheduledDate.split("T")[0]}
          </h6>
        );
      },
    },
    {
      name: "Remaining Days",
      selector: (row) => {
        function getDifferenceInDays(date1, date2) {
          // Convert both dates to milliseconds
          const date1Millis = new Date(date1).getTime();
          const date2Millis = new Date(date2).getTime();

          // Calculate the difference in milliseconds
          const diffMillis = Math.abs(date2Millis - date1Millis);

          // Convert milliseconds to days
          const diffDays = Math.ceil(diffMillis / (1000 * 60 * 60 * 24));

          return diffDays;
        }
        return (
          <h6 className="text-primary">
            {getDifferenceInDays(
              row.interviewEndDate.split("T")[0],
              Date.now()
            )}
          </h6>
        );
      },
    },
    // {
    //   name: "Status",
    //   selector: (row) => {
    //     return <h6 className="text-primary">{row.status}</h6>;
    //   },
    // },
    {
      name: "Rounds",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {row.stageStatus.interview === "ongoing"
              ? "interview"
              : row.stageStatus.coding === "ongoing"
              ? "coding"
              : "-"}
          </h6>
        );
      },
    },

    // {
    //   name: "Matching Score",
    //   selector: (row) => {
    //     return (
    //       <h6
    //         className={
    //           row.score >= 90
    //             ? "text-success"
    //             : row.score >= 50
    //             ? "text-warning"
    //             : "text-danger"
    //         }
    //       >
    //         {row.score}
    //       </h6>
    //     );
    //   },
    // },

    {
      name: "",
      selector: (row) => {
        return (
          <div className="">
            <ActionButton
              name="scheduled"
              downloadCv={<button>Download CV</button>}
              drop={
                <div>
                  <button
                    className="text-danger"
                    to="/dashboard/my_jobs/candidates_list"
                    style={{ textDecoration: "none" }}
                  >
                    <h6>Drop</h6>
                  </button>
                </div>
              }
            />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    table: {},
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "rgb(156, 164, 209)",
        fontSize: "14px",
        color: "rgb(63, 76, 147)",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        selectableRows={true}
        onSelectedRowsChange={selectHandlerFunction}
      />
      {selectedRows.length > 0 && (
        <div>
          <div className="my-4">
            <div>
              <button className="btn btn-danger" onClick={handleDropCandidates}>
                <b>Drop Selected Candidates</b>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Scheduled;
