import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionButton from "./ActionButton";
import axios from "axios";
import { API_URL } from "./env";
import { authContext } from "../hooks/authContext";
import ViewDetailsModal from "./ViewDetailsModal";
function Rejected({ jobId }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectHandlerFunction = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const handleScheduleCandidates = () => {
    const id = selectedRows.map((obj) => obj.id);
  };
  const { user } = useContext(authContext);
  const [tableData, setTableData] = useState([]);
  const getData = async () => {
    try {
      const results = await axios.get(
        `${API_URL}/candidate/get-candidates/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setTableData(results.data.filter((candidate) => candidate.score <= 50));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      name: "Candidate Name",
      selector: (row) => {
        return (
          <h6 className="text-bold text-primary text-wrap">
            {row.resume.full_name[0].toLowerCase()}
          </h6>
        );
      },
    },
    {
      name: "Candidate Title",
      selector: (row) => {
        return <h6 className="text-primary text-wrap">{row.jobTitle}</h6>;
      },
    },

    {
      name: "Experience",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {row.resume.total_experience ? row.resume.total_experience : 0}
          </h6>
        );
      },
    },

    {
      name: "Uploaded Date",
      selector: (row) => {
        return <h6 className="text-primary">{row.date.split("T")[0]}</h6>;
      },
    },
    {
      name: "Stage",
      selector: (row) => {
        return <h6 className="text-primary text-wrap">{row.stage}</h6>;
      },
    },
    {
      name: "Reason",
      selector: (row) => {
        return <ViewDetailsModal details={row} />;
      },
    },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <ActionButton
              name="reject"
              downloadCv={
                <div>
                  <button className="">Download CV</button>
                </div>
              }
              schedule={
                <div className="">
                  <button
                    className="text-success"
                    to="/dashboard/my_jobs/candidates_list"
                    style={{ textDecoration: "none" }}
                  >
                    <b>Schedule</b>
                  </button>
                </div>
              }
            />
          </>
        );
      },
    },
  ];

  const customStyles = {
    table: {},
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "rgb(156, 164, 209)",
        fontSize: "14px",
        color: "rgb(63, 76, 147)",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        selectableRows={true}
        onSelectedRowsChange={selectHandlerFunction}
      />
      {selectedRows.length > 0 && (
        <div>
          <div className="my-4">
            <div className="my-2">
              <button
                className="btn btn-success"
                onClick={handleScheduleCandidates}
              >
                <b>Schedule Selected Candidates</b>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Rejected;
