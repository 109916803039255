import DummyTable from "./DummyTable";
import axios from "axios";
import { authContext } from "../hooks/authContext";
import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "./env";
import { Link } from "react-router-dom";

function JobSection() {
  const [userData, setUserData] = useState([]);

  const [rowCount, setRowCount] = useState(0); // State to store the row count
  const { user } = useContext(authContext);
  //const userId = user.id;
  const usertoken = user.token;
  const [selectedStatus, setSelectedStatus] = useState("All Jobs");
  const [filteredData, setFilteredData] = useState([]);

  const tab3getHandler = async () => {
    try {
      const response = await axios.get(`${API_URL}/job/get-job`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const fetchedData = response.data;
        setUserData(response.data);
        setFilteredData(response.data.reverse());

        setRowCount(response.data.length);
      } else {
        console.log("Request failed with status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Status Code:", error.response.status);
        console.error("Error Headers:", error.response.headers);
      }
    }
  };

  useEffect(() => {
    tab3getHandler();
  }, []);

  useEffect(() => {
    // Log userData when it changes
    setRowCount(userData.length);
  }, [userData]);

  useEffect(() => {
    // Access the status prop in useEffect
    filterData(selectedStatus); // Call filterData whenever status changes
  }, [selectedStatus]);

  // const reversedData = [...userData].reverse();

  const filterData = (selectedStatus) => {
    let filteredData = userData;
    if (selectedStatus === "Active") {
      filteredData = userData.filter((row) => {
        const expDate = new Date(row.expDate);
        const differenceInMilliseconds = expDate - new Date();
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );
        return differenceInDays > 0;
      });
    } else if (selectedStatus === "Expired") {
      filteredData = userData.filter((row) => {
        const expDate = new Date(row.expDate);
        const differenceInMilliseconds = expDate - new Date();
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );
        return differenceInDays <= 0;
      });
    }

    setFilteredData(filteredData);
    setRowCount(filteredData.length);
  };

  function makeitexpireHandler(data) {
    const _id = data._id;
    // Api logic

    axios
      .put(
        `${API_URL}/job/expire-job/${_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      )
      .then((res) => tab3getHandler())
      .catch((error) => console.log(error));
  }

  // Set default selectedStatus to "All Jobs"

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <>
      <div className="p-4 border">
        <div>
          <div className="d-flex justify-content-between my-3">
            <div>
              <b className="h5">
                My Jobs <span className="text-secondary"> ({rowCount})</span>
              </b>
            </div>
            <div>
              Job Status{" "}
              <select
                className="form-select-sm text-secondary"
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <option value="All Jobs">All Jobs</option>
                <option value="Active">Active</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
          </div>
          <div>
            {/* Pass the selected status to the DummyTable component */}
            <DummyTable
              tableData={filteredData}
              expireFunction={makeitexpireHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobSection;
