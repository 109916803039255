import React from "react";
import "./App.css";
import { useContext } from "react";
import { authContext } from "./hooks/authContext";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/LoginComponent";
// import Home from './components/HomeComponent';
import RegistrationComponent from "./components/RegistrationComponent";
import LeftNavbar from "./components/LeftNavbar";
import JobSection from "./components/JobSection";
import PlanComponent from "./components/PlanComponent";
import EmployerProfile from "./components/EmployerProfile";
import PostJob from "./components/PostJob";

import JobDetails from "./components/JobDetails";

import UpdateJobDetails from "./components/UpdateJobDetails";

import AllCompanyComponent from "./components/AllCompanyComponent";
import OtpComponent from "./components/OtpComponent";

import BulkUpload from "./components/BulkUpload";
import JobPreference from "./components/JobPreference";
import AdminJobSection from "./components/AdminJobSection";
import AdminPostJob from "./components/AdminPostJob";

import CandidateViewTab from "./components/CandidateViewTab";
import InterviewCandidateListTab from "./components/InterviewCandidateListTab";

function App() {
  const { user } = useContext(authContext);

  return (
    <Routes>
      <Route
        path="/*"
        element={user ? <LeftNavbar /> : <Navigate to="/login" />}
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate to="/dashboard" />}
      />
      <Route
        path="/signup"
        element={!user ? <RegistrationComponent /> : <Navigate to="/" />}
      />

      <Route
        path="/dashboard"
        element={user ? <LeftNavbar /> : <Navigate to="/login" />}
      >
        <Route
          path="/dashboard/employers_profile"
          element={<EmployerProfile />}
        />
        <Route path="/dashboard/postajob" element={<PostJob />} />
        <Route path="/dashboard/my_jobs" element={<JobSection />} />
        {/* <Route path="/dashboard/my_jobs/candidates_list" element={<CandidatesList />} /> */}

        <Route
          path="/dashboard/my_jobs/candidates_list/screening"
          element={<CandidateViewTab />}
        />
        <Route
          path="/dashboard/my_jobs/candidates_list/interview"
          element={<InterviewCandidateListTab />}
        />

        <Route path="/dashboard/my_jobs/bulk_upload" element={<BulkUpload />} />
        <Route path="/dashboard/plans_billing" element={<PlanComponent />} />
        <Route
          path="/dashboard/all-company"
          element={<AllCompanyComponent />}
        />
        <Route
          path="/dashboard/company-job/:company/:id"
          element={<AdminJobSection />}
        />
        <Route
          path="/dashboard/postajob-admin/:id"
          element={<AdminPostJob />}
        />
        <Route path="/dashboard/job-get/:_id" element={<JobDetails />} />
        <Route
          path="/dashboard/job-update/:_id"
          element={<UpdateJobDetails />}
        />
        <Route
          path="/dashboard/jobpreference/:_id"
          element={<JobPreference />}
        />
      </Route>

      <Route path="/otp" element={<OtpComponent />} />
    </Routes>
  );
}

export default App;
