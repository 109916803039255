import * as React from "react";

import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
export default function ResultBarChart({ dataset }) {
  let data = [];
  Object.entries(dataset).forEach(([key, value], index) => {
    data.push({
      label: key,
      value,
      id: index,
    });
  });

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {data.map((d, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          <Gauge
            width={120}
            height={120}
            value={d.value}
            startAngle={0}
            endAngle={360}
            valueMax={100}
            valueMin={0}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 10,
                fontWeight: "bold",
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill:
                  d.value > 80 ? "#52b202" : d.value <= 50 ? "red" : "orange",
              },
            }}
            text={({ value, valueMax }) => `${value} / ${valueMax}`}
          />
          <p
            style={{
              fontSize: "12px",
              textWrap: "wrap",
            }}
          >
            {d.label}
          </p>
        </div>
      ))}
    </div>
  );
}
