import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import { API_URL } from "./env";
import { authContext } from "../hooks/authContext";
import axios from "axios";

function UnscheduledMenuItems(props) {
  const handleClose = () => {};
  const { user } = useContext(authContext);
  const handleCandidate = async () => {
    try {
      const result = await axios.post(
        `${API_URL}/candidate/shedule-interview-candidate/${props.jobId}/${props.id}?stage=interview&status=scheduled`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      alert(result.data.message);
      props.getData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <MenuItem onClick={() => {}}>{props.downLoadCv}</MenuItem>
      <MenuItem onClick={() => handleCandidate("selected")}>
        {props.accept}
      </MenuItem>
      <MenuItem onClick={handleClose}>{props.reject}</MenuItem>
    </>
  );
}

export default UnscheduledMenuItems;
