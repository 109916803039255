// TopNavBar.js
import React from "react";
import { NavLink } from "react-router-dom";

import "../css/TopNavBar.css";

import Notification from "./Notifications";

const TopNavBar = ({ onLogout }) => {
  return (
    <div className="fixed-top p-0 m-0" style={{ zIndex: "999" }}>
      <nav className="navbar navbar-expand-md navbar-dark navbar-custom">
        <div className="container-fluid mx-5">
          {/* Navigation Links */}
          <div className="navbar-collapse">
            <div className="navbar-nav">
              <div>
                <img
                  src="https://w7.pngwing.com/pngs/704/830/png-transparent-shopping-bags-trolleys-logo-shopping-cart-shopping-bag-white-luggage-bags-rectangle.png"
                  alt="Brand Logo"
                  className="brand-logo"
                />
              </div>

              <div>
                <span className="brand">SWARMX</span>
              </div>
            </div>
          </div>

          {/* Language and Logout Section */}
          <div className="d-flex align-items-center">
            <NavLink to="/customersupports" className="nav-link mx-2 active">
              Contact Us
            </NavLink>
            <div className="notification-icon mx-3">
              <Notification />
            </div>

            <div className="btn btn-danger" onClick={onLogout}>
              Logout
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNavBar;
