import React, { useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import { authContext } from "../hooks/authContext";
import { API_URL } from "./env";
import { UploadContext } from "../context/UploadContext";
import { NotificationContext } from "../context/NotificationContext";

function BulkUpload({ jobId, jobName }) {
  const {
    uploadProgress,
    setUploadProgress,
    uploads,
    startUpload,
    isUploading,
    failedUploads,
    retryUpload,
  } = useContext(UploadContext);

  const [sourceOptions, setSourceOptions] = useState([]);
  const { user } = useContext(authContext);
  const isAdmin = user.user.userRole === "admin";
  const [source, setSource] = useState(
    isAdmin
      ? null
      : {
          source: "Employer",
          label: "Employer",
          value: "Employer",
        }
  );
  const [showProceed, setShowProceed] = useState(isAdmin ? false : true);
  const [showUpload, setShowUpload] = useState(isAdmin ? false : true);
  const [filesUpload, setFilesToUpload] = useState([]);
  const usertoken = user.token;

  const uploadFunction = (file, index, jobId) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${API_URL}/upload/add-files/${jobId}`, formData, {
      headers: {
        Authorization: `Bearer ${usertoken}`,
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [jobId]: {
            ...(prevProgress[jobId] || {}),
            [index]: percentCompleted,
          },
        }));
      },
    });
  };

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(`${API_URL}/source/get-source`, {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          const responseData = response.data;
          const fetchedSources = responseData.sources || [];
          const formattedSources = fetchedSources.map((source) => ({
            value: source.value,
            label: source.label,
          }));
          setSourceOptions(formattedSources);
        } else {
          console.log("Request failed with status code:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Status Code:", error.response.status);
          console.error("Error Headers:", error.response.headers);
        }
      }
    };

    fetchSources();
  }, [usertoken]);

  const handleSourceChange = (selectedOption) => {
    setSource(selectedOption);
    setShowProceed(true);
  };

  const handleOtherSourceChange = (newValue) => {
    newValue = newValue.toLowerCase();
    const newSource = {
      value: newValue,
      label: newValue,
    };
    setSource(newSource);
    setShowProceed(true);
  };

  const handleProceed = () => {
    setShowUpload(true);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    try {
      const uploadResponse = await axios.post(
        `${API_URL}/upload/initiate-add-files`,
        {
          source: source.label,
          jobId: jobId,
        },
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      if (uploadResponse.data.message) {
        const uploadFunction = (file, index, jobId) => {
          const formData = new FormData();
          formData.append("file", file);

          return axios.post(`${API_URL}/upload/add-files/${jobId}`, formData, {
            headers: {
              Authorization: `Bearer ${usertoken}`,
              "content-type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [jobId]: {
                  ...(prevProgress[jobId] || {}),
                  [index]: percentCompleted,
                },
              }));
            },
          });
        };

        startUpload(filesUpload, uploadFunction, jobName, jobId);
      }

      if (isAdmin) {
        const response = await axios.post(
          `${API_URL}/source/post-source`,
          {
            source: source.value,
            label: source.label,
            value: source.value,
          },
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        );
      }

      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [jobId]: {},
      }));
    } catch (error) {
      console.error("Upload error:", error.message);
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxFiles: 30,
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
    },
  });

  const fileObject = uploads.find((file) => file.jobId === jobId);
  const uploadingFiles = fileObject?.files.map((file, index) => (
    <li key={file.path}>
      <div className="d-flex justify-content-between">
        <div>{file.path}</div>
        {isUploading[jobId] && (
          <div>
            {uploadProgress[jobId] &&
            uploadProgress[jobId][index] &&
            uploadProgress[jobId][index] > 99
              ? "✅"
              : isUploading[jobId] && (
                  <progress
                    value={
                      uploadProgress[jobId]
                        ? uploadProgress[jobId][index] || 0
                        : 0
                    }
                    max="100"
                  >
                    {uploadProgress[jobId]
                      ? uploadProgress[jobId][index] || 0
                      : 0}
                    %
                  </progress>
                )}
          </div>
        )}
        {failedUploads[jobId]?.includes(index) && (
          <button
            onClick={() =>
              retryUpload(file, index, uploadFunction, jobName, jobId)
            }
            className="px-2 py-1 ml-2 bg-red-500 text-white rounded-md"
          >
            Retry
          </button>
        )}
      </div>
    </li>
  ));

  const files = acceptedFiles.map((file, index) => (
    <li key={file.path}>
      <div className="d-flex justify-content-between">
        <div>{file.path}</div>
        {isUploading[jobId] && (
          <div>
            {uploadProgress[jobId] && uploadProgress[jobId][index] > 99
              ? "✅"
              : isUploading[jobId] && (
                  <progress
                    value={
                      uploadProgress[jobId]
                        ? uploadProgress[jobId][index] || 0
                        : 0
                    }
                    max="100"
                  >
                    {uploadProgress[jobId]
                      ? uploadProgress[jobId][index] || 0
                      : 0}
                    %
                  </progress>
                )}
          </div>
        )}
        {failedUploads[jobId]?.includes(index) && (
          <button
            onClick={() =>
              retryUpload(file, index, uploadFunction, jobName, jobId)
            }
            className="px-2 py-1 ml-2 bg-red-500 text-white rounded-md"
          >
            Retry
          </button>
        )}
      </div>
    </li>
  ));

  return (
    <>
      <h2>{jobName}</h2>
      {/* Source dropdown */}
      {isAdmin && (
        <div style={{ width: "30%" }}>
          <h4>Select Source</h4>
          <CreatableSelect
            options={sourceOptions}
            value={isAdmin ? source : "Employer"}
            isDisabled={!isAdmin}
            onChange={handleSourceChange}
            onCreateOption={handleOtherSourceChange}
            isClearable
            placeholder={isAdmin ? "Select or create source" : "Employer"}
            styles={{
              control: (provided, state) => ({
                ...provided,
                textAlign: "left",
                marginTop: "10px",
                padding: "5px",
                border: state.isFocused ? "1px solid grey" : "0.5px solid grey",
                boxShadow: state.isFocused ? "0 0 0 1px grey" : "none",
                "&:hover": {
                  border: state.isFocused
                    ? "1px solid grey"
                    : "0.5px solid grey",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              input: (provided) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
          />
        </div>
      )}

      {/* Proceed button */}
      {showProceed && isAdmin && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button className="btn btn-primary w-50" onClick={handleProceed}>
            Proceed File Upload
          </button>
        </div>
      )}

      {/* Upload section */}
      {showUpload && (
        <div
          style={{
            borderRadius: "10px",
            border: "2px dotted grey",
            padding: "15px",
            margin: "30px",
          }}
        >
          <section className="container text-center">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <button
                type="button"
                className="btn btn-primary-outline"
                onClick={open}
              >
                Select or Drop Files
              </button>
            </div>
            <aside>
              <ul>{isUploading[jobId] ? uploadingFiles : files}</ul>
            </aside>
            {filesUpload.length > 0 && (
              <div style={{ textAlign: "center" }}>
                <button
                  className="btn btn-primary mt-4 w-50"
                  onClick={handleUpload}
                >
                  {isUploading[jobId] ? "Uploading..." : "Upload"}
                </button>
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
}

export default BulkUpload;
