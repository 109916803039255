import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { authContext } from "../hooks/authContext";
import TopNavBar from "./TopNavBar";

const Home = () => {
  const { dispatch } = useContext(authContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  return (
    <div>
      <TopNavBar onLogout={logoutHandler} />
      <div style={{ paddingTop: "6%" }}></div>
    </div>
  );
};

export default Home;
