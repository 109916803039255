import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Rejected from "./Rejected";
import Selected from "./Selected";
import Scheduled from "./Scheduled";
import UnScheduled from "./Unscheduled";
import { useLocation } from "react-router-dom";
import InterviewRejected from "./InterviewRejected";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InterviewCandidateListTab({}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { state } = useLocation();

  const jobId = state.jobId;

  return (
    <Box sx={{ width: "100%" }}>
      <h3>Interview</h3>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="To be scheduled" className="fs-6" {...a11yProps(0)} />
          <Tab label="Scheduled" className="fs-6" {...a11yProps(1)} />
          <Tab
            label="Shortlisted Candidates"
            className="fs-6"
            {...a11yProps(2)}
          />
          <Tab label="Rejected" className="fs-6" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={2}>
        <Selected jobId={jobId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InterviewRejected jobId={jobId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Scheduled jobId={jobId} />
      </TabPanel>
      <TabPanel value={value} index={0}>
        <UnScheduled jobId={jobId} />
      </TabPanel>
    </Box>
  );
}
