import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { authContext } from "../hooks/authContext";

import ActionButton from "./ActionButton";
import { API_URL } from "./env";

import ViewInterviewDetails from "./ViewInterviewDetails";
function InterviewRejected({ jobId }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectHandlerFunction = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };
  const handleDropCandidates = () => {
    const id = selectedRows.map((obj) => obj.id);
    console.log(id);
  };
  const [tableData, setTableData] = useState([]);

  const { user } = useContext(authContext);
  const getData = async () => {
    try {
      const results = await axios.get(
        `${API_URL}/candidate/get-candidates/${jobId}?status=rejected&stage=interview`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setTableData(results.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      name: "Candidate Name",
      selector: (row) => {
        return (
          <h6 className="text-bold text-primary">
            {row.resume.full_name[0].toLowerCase()}
          </h6>
        );
      },
    },
    {
      name: "Candidate Title",
      selector: (row) => {
        return <h6 className="text-primary text-wrap">{row.jobTitle}</h6>;
      },
    },

    {
      name: "Experience",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {" "}
            {row.resume.total_experience ? row.resume.total_experience : 0}
          </h6>
        );
      },
    },

    {
      name: "Uploaded Date",
      selector: (row) => {
        return <h6 className="text-primary">{row.date.split("T")[0]}</h6>;
      },
    },
    {
      name: "Stage",
      selector: (row) => {
        return <h6 className="text-primary">{row.stage}</h6>;
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return <h6 className="text-primary">{row.status}</h6>;
      },
    },

    {
      name: "Reason",
      selector: (row) => {
        return <ViewInterviewDetails details={row} />;
      },
    },
    // {
    //   name: "ACTIONS",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <div className="flex">
    //           <div
    //             className=""
    //             style={{
    //               marginRight: "2px",
    //             }}
    //           >
    //             <Link
    //               className="btn btn-success"
    //               to="/dashboard/my_jobs/candidates_list"
    //             >
    //               <b>Accept</b>
    //             </Link>
    //           </div>

    //           <div>
    //             <Link
    //               className="btn btn-danger"
    //               to="/dashboard/my_jobs/candidates_list"
    //             >
    //               <b>Reject</b>
    //             </Link>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      name: "",
      selector: (row) => {
        return (
          <div className="">
            <ActionButton
              name="select"
              downloadCv={<button>Download CV</button>}
              drop={
                <div>
                  <button
                    className="text-danger"
                    to="/dashboard/my_jobs/candidates_list"
                    style={{ textDecoration: "none" }}
                  >
                    <h6>Drop</h6>
                  </button>
                </div>
              }
            />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    table: {},
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "rgb(156, 164, 209)",
        fontSize: "14px",
        color: "rgb(63, 76, 147)",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        selectableRows
        onSelectedRowsChange={selectHandlerFunction}
      />

      {selectedRows.length > 0 && (
        <div>
          <div className="my-4">
            <div className="my-2">
              <button className="btn btn-danger" onClick={handleDropCandidates}>
                <b>Drop Selected Candidates</b>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InterviewRejected;
