import React from "react";

import MenuItem from "@mui/material/MenuItem";

function SelectedMenuItems(props) {
  const handleClose = () => {};
  return (
    <>
      <MenuItem onClick={handleClose}>{props.downloadCv}</MenuItem>
      <MenuItem onClick={handleClose}>{props.drop}</MenuItem>
    </>
  );
}

export default SelectedMenuItems;
