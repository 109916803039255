import React, { createContext, useContext, useState } from "react";
import { NotificationContext } from "./NotificationContext";

const UploadContext = createContext();

const UploadProvider = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploads, setUploads] = useState([]);
  const [isUploading, setIsUploading] = useState({});
  const [failedUploads, setFailedUploads] = useState({});

  const { setNotifications } = useContext(NotificationContext);

  const startUpload = (files, uploadFunction, jobName, jobId) => {
    const newUpload = {
      jobId,
      files,
      jobName,
      progress: {},
    };

    setUploads((prevUploads) => [...prevUploads, newUpload]);
    setIsUploading((prev) => ({ ...prev, [jobId]: true }));

    files.forEach((file, index) => {
      uploadFunction(file, index, jobId)
        .then((res) => {
          setUploads((prevUploads) => {
            const updatedUploads = prevUploads.map((upload) => {
              if (upload.jobId === jobId) {
                const updatedProgress = {
                  ...upload.progress,
                  [index]: 100,
                };
                return { ...upload, progress: updatedProgress };
              }
              return upload;
            });
            return updatedUploads;
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploads((prevUploads) => {
            const updatedUploads = prevUploads.map((upload) => {
              if (upload.jobId === jobId) {
                const updatedProgress = {
                  ...upload.progress,
                  [index]: 0,
                };
                return { ...upload, progress: updatedProgress };
              }
              return upload;
            });
            return updatedUploads;
          });
          setFailedUploads((prevFailed) => ({
            ...prevFailed,
            [jobId]: [...(prevFailed[jobId] || []), index],
          }));
          setNotifications((prev) => [
            ...prev,
            {
              message: `File ${file.path} are not uploaded at ${jobName}`,
              status: false,
              id: file.path,
            },
          ]);
        })
        .finally(() => {
          setUploads((prevUploads) => {
            const uploadIndex = prevUploads.findIndex(
              (upload) => upload.jobId === jobId
            );

            if (uploadIndex !== -1) {
              const completedUploads = prevUploads[uploadIndex].files.length;
              const allUploaded = Object.values(
                prevUploads[uploadIndex].progress
              ).filter((p) => p === 100).length;

              if (allUploaded === completedUploads) {
                setNotifications((prev) => [
                  ...prev,
                  {
                    message: `Files for ${jobName} uploaded successfully`,
                    status: true,
                    id: uploadIndex,
                  },
                ]);

                setIsUploading((prev) => ({ ...prev, [jobId]: false }));

                // Reset progress for the completed job and filter out the completed job
                return prevUploads
                  .map((upload) => {
                    if (upload.jobId === jobId) {
                      return {
                        ...upload,
                        progress: {}, // Reset progress to {}
                      };
                    }
                    return upload;
                  })
                  .filter((upload) => upload.jobId !== jobId); // Filter out the completed job
              }
            }

            return prevUploads;
          });
        });
    });
  };

  const retryUpload = (file, index, uploadFunction, jobName, jobId) => {
    setFailedUploads((prevFailed) => ({
      ...prevFailed,
      [jobId]: (prevFailed[jobId] || []).filter((i) => i !== index),
    }));
    uploadFunction(file, index, jobId)
      .then((res) => {
        setUploads((prevUploads) => {
          const updatedUploads = prevUploads.map((upload) => {
            if (upload.jobId === jobId) {
              const updatedProgress = {
                ...upload.progress,
                [index]: 100,
              };
              return { ...upload, progress: updatedProgress };
            }
            return upload;
          });
          return updatedUploads;
        });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setUploads((prevUploads) => {
          const updatedUploads = prevUploads.map((upload) => {
            if (upload.jobId === jobId) {
              const updatedProgress = {
                ...upload.progress,
                [index]: 0,
              };
              return { ...upload, progress: updatedProgress };
            }
            return upload;
          });
          return updatedUploads;
        });
        setFailedUploads((prevFailed) => ({
          ...prevFailed,
          [jobId]: [...(prevFailed[jobId] || []), index],
        }));
      });
  };

  return (
    <UploadContext.Provider
      value={{
        uploadProgress,
        setUploadProgress,
        uploads,
        startUpload,
        isUploading,
        setIsUploading,
        setUploads,
        failedUploads,
        retryUpload,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export { UploadContext, UploadProvider };
