import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { authContext } from "../hooks/authContext";
import { API_URL } from "./env";

const interviewOptions = [
  {
    label: "Both Coding and Technical Round",
    value: "both",
  },
  {
    label: "Coding Round",
    value: "coding",
  },
  {
    label: "Technical Round",
    value: "technical",
  },
];

function InterviewRoundSelection({ jobId }) {
  const { user } = useContext(authContext);

  const usertoken = user.token;

  const [opted, setOpted] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const getSelectedOption = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/get-interviewrounds/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      setOpted(response.data.selectedInterviewRound.selectedInterviewRound);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSelectedOption();
  }, [opted]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_URL}/post-interviewrounds/${jobId}`,
        {
          selectedInterviewRound: selectedOption,
        },
        {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      setOpted(response.data.selectedInterviewRound.selectedInterviewRound);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mb-5">
      <h3 className="page-title">Choose Interview Rounds</h3>

      <form onSubmit={submitHandler}>
        <div className="d-flex align-items-center">
          <div>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="interview round"
              options={interviewOptions}
              placeholder={
                opted
                  ? interviewOptions.find((option) => option.value === opted)
                      .label
                  : "Select Interview Rounds"
              }
              onChange={(newOption) => setSelectedOption(newOption.value)}
            />
          </div>

          <div>
            <button className="save-button blue-btn">Choose</button>
          </div>
          <div>
            <p className="lead mx-2 my-1">
              {opted.length > 0 && `Selected ${opted}`}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InterviewRoundSelection;
