import React from "react";
import ReactDOM from "react-dom/client";
import { AuthContextprovider } from "./hooks/authContext";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter } from "react-router-dom";
import { UploadProvider } from "./context/UploadContext";
import { NotificationProvider } from "./context/NotificationContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthContextprovider>
    <NotificationProvider>
      <UploadProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UploadProvider>
    </NotificationProvider>
  </AuthContextprovider>
);
