import React, { useEffect, useState } from "react";
import Select from "react-select";
import { API_URL } from "./env";
import axios from "axios";

import { useNavigate } from "react-router-dom";

function AllCompanyComponent() {
  const [companies, setCompanies] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    getCompanies();
  }, []);

  const filterCompanies = (companies) => {
    const filtered = companies.map((company) => {
      if (company.company.company.toLowerCase() === search.toLowerCase()) {
        return company;
      }
      return null;
    });
    const fi = filtered.filter(function (el) {
      return el !== null;
    });
    setFilteredData(fi);
  };

  useEffect(() => {
    filterCompanies(companies);
  }, [search]);

  const getCompanies = async () => {
    try {
      const response = await axios.get(`${API_URL}/company/get-companies`);
      // console.log(response.data.companies)
      setCompanies(response.data.companies);
      setFilteredData(response.data.companies);
      const companyNameResults = response.data.companies.map((company) => {
        return {
          label: company.company.company,
          value: company.company.company,
        };
      });
      setCompanyOptions(companyNameResults);
    } catch (error) {}
  };

  return (
    <div>
      <div className="p-2 border">
        <div className="d-flex align-items-center justify-content-between my-3">
          <div style={{ width: "25%" }}>
            <Select
              openMenuOnClick={false}
              isSearchable="true"
              openMenuOnFocus={false}
              options={companyOptions}
              placeholder={search ? search : "Search Company"}
              onChange={(selected) => {
                setSearch(selected.value);
              }}
            />
          </div>

          <div>Total Companies : {filteredData.length}</div>
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-around my-3">
          {filteredData?.map((company) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                }}
                className="w-auto border border-primary rounded py-2 px-3"
                onClick={() => {
                  navigate(
                    `/dashboard/company-job/${company.company.company}/${company.company.userId}`
                  );
                }}
              >
                <span className="text-primary">{company.company.company}</span>
                <span className="text-dark fw-bold">({company.jobLength})</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AllCompanyComponent;
