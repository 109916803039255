import { MenuItem } from "@mui/material";
import React from "react";

function RejectedMenuItems(props) {
  const handleClose = () => {};

  return (
    <>
      <MenuItem onClick={handleClose}>{props.downloadCv}</MenuItem>
      <MenuItem onClick={handleClose}>{props.schedule}</MenuItem>
    </>
  );
}

export default RejectedMenuItems;
