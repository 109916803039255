import { useState } from "react";
import "../css/plan.css";
import master_card from "../images/master-card.png";
import DataTable from "react-data-table-component";

export default function PlanComponent() {
  const data = [
    {
      id: 1,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 2,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 3,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 4,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 5,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 6,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 7,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 8,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 9,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 10,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 11,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 12,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 13,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 14,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 15,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 16,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 17,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 18,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 19,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 20,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 21,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 22,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 23,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 24,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 25,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 26,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 27,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 28,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 29,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 30,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
    {
      id: 31,
      date: "Dec 7,2019 23:26",
      plan: "premium",
      amount: "$999 usd",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5; // Set your desired number of rows per page

  const paginatedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const CustomPagination = ({
    totalRows,
    rowsPerPage,
    currentPage,
    setCurrentPage,
  }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    //const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handleNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePageClick = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

    return (
      <div className="custom-pagination-container mb-3">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="btn btn-light text-dark rounded-circle"
        >
          <ion-icon name="arrow-back-outline"></ion-icon>
        </button>
        {visiblePageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`btn ${
              pageNumber === currentPage
                ? "btn-primary "
                : "btn-outline-primary text-dark"
            } rounded-circle`}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="btn btn-light text-dark rounded-circle"
        >
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </button>
      </div>
    );
  };

  // Function to get visible page numbers based on the current page
  function getVisiblePageNumbers(currentPage, totalPages) {
    const pageRange = 2; // Number of pages to show on each side of the current page

    let startPage = Math.max(currentPage - pageRange, 1);
    let endPage = Math.min(currentPage + pageRange, totalPages);

    // Adjust if the visible pages are less than the specified range
    const visiblePages = endPage - startPage + 1;
    if (visiblePages < 5 && startPage > 1) {
      startPage = Math.max(endPage - 4, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }

  const columns = [
    {
      name: "#ID",
      selector: (row) => {
        return (
          <div className=" fw-bolder" style={{ fontSize: "15px" }}>
            {row.id}
          </div>
        );
      },
    },
    {
      name: "DATE",
      selector: (row) => {
        return <div style={{ fontSize: "15px" }}>{row.date}</div>;
      },
    },
    {
      name: "PLAN",
      selector: (row) => {
        return <div style={{ fontSize: "15px" }}>{row.plan}</div>;
      },
    },
    {
      name: "AMOUNT",
      selector: (row) => {
        return <div style={{ fontSize: "15px" }}>{row.amount}</div>;
      },
    },
    {
      name: "",
      selector: (row) => {
        return (
          <div>
            <div>
              <button className="btn btn-light">
                <ion-icon name="download-outline" size="small"></ion-icon>
              </button>
            </div>{" "}
            {row.logo}
          </div>
        );
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        padding: "0.5rem",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",

        backgroundColor: "lightgray",
        fontSize: "15px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div className=" mt-4  mx-3">
      <div className="row">
        <div className="col-md-5 d-flex flex-column align-self-stretch ">
          <div className="card p-4 mb-3 flex-1">
            <h6>Current plan</h6>
            <h2>Premium</h2>
            <p className="text-justify">
              hfddfhgud ifer jfgurhg rfeheruhg ufhrureh gfyshuief svuahguiraeh
              uhrguiahgufeg reug98ewrug 8ure89ywt
            </p>
            <div className="container-fluid  ">
              <button type="button" className="custom-button">
                Change plan
              </button>
              <button type="button" className="custom-button">
                Cancel plan
              </button>
            </div>
          </div>
          <div className=" card px-4 py-4">
            <h6>Next Inovices</h6>
            <h2 className="text-primary">$59.00 USD</h2>
            <h6>Nov 28,2021</h6>
            <p style={{ marginBottom: "5px" }}>
              Package started: <span className="">jan 28,2021</span>
            </p>
            <p style={{ marginBottom: "5px" }}>
              You have to pay this amount of money every month
            </p>
            <button
              type="button"
              className="btn btn-primary align-items-center"
            >
              Pay Now <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
          </div>
        </div>

        <div className="col-md-7 d-flex flex-column align-self-stretch">
          <div className="card  px-4 py-3 mb-3  align-self-stretch">
            <h6 style={{ marginBottom: "5px" }}>Plan Benefits</h6>
            <p style={{ marginBottom: "5px" }}>
              fjkfg qjfeww dgjidg ijsgwijre jsgghuhge
            </p>
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: "5px" }}>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon> 6
                  Active Jobs
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon>{" "}
                  Highlight Job with colors
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon> 60
                  Days Resumes Visibility
                </p>
              </div>
              <div className="col-md-6">
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon>{" "}
                  Urgent & Feature Jobs
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon>{" "}
                  Access & Saved 20 Candidates
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="checkmark-done" color="primary"></ion-icon>{" "}
                  24/7 Critical Support
                </p>
              </div>
            </div>
            <hr style={{ marginBottom: "5px", marginTop: "5px" }}></hr>
            <p style={{ marginBottom: "5px" }}>Reamining</p>
            <div className="row" style={{ marginBottom: "1px" }}>
              <div className="col-md-6">
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="close-circle" color="danger"></ion-icon> 9
                  Resumes Access
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="close-circle" color="danger"></ion-icon> 4
                  Active jobs
                </p>
              </div>
              <div className="col-md-6">
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="close-circle" color="danger"></ion-icon> 21
                  Days Resumes Visibility
                </p>
              </div>
            </div>
          </div>
          <div className="card  px-4 py-3 mb-3 align-self-stretch">
            <div className="row" style={{ marginBottom: "5px" }}>
              <div className="col-md-9">
                <h6 style={{ marginBottom: "5px" }}>Payment Card</h6>
              </div>
              <div className="col-md-3">
                <p style={{ marginBottom: "5px" }}>
                  <ion-icon name="create-outline"></ion-icon> Edit card
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <img
                  src={master_card}
                  alt="img"
                  className="w-100 h-100 img-responsive"
                />
              </div>
              <div className="col-md-7">
                <p style={{ marginBottom: "5px" }}>Name on card</p>
                <h6>jhon</h6>
              </div>
              <div className="col-md-3">
                <p style={{ marginBottom: "5px" }}>Expire Date</p>
                <h6>12/29</h6>
              </div>
            </div>
            <hr style={{ marginBottom: "5px", marginTop: "5px" }}></hr>
            <h3 style={{ marginBottom: "5px" }}>6543 **** **** ****</h3>
          </div>
        </div>
      </div>
      <div className=" card datatable-container">
        <p className="mx-3 mt-3" style={{ marginBottom: "5px" }}>
          Leastest Inovices
        </p>
        <DataTable
          columns={columns}
          data={paginatedData}
          customStyles={customStyles}
        />
        <CustomPagination
          totalRows={data.length}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}
