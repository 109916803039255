import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import BulkUpload from "./BulkUpload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "100vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 4,
};

export default function UploadModal({ jobId, jobName }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button className="btn btn-outline-primary mx-2 " onClick={handleOpen}>
        <b>Upload</b>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BulkUpload jobId={jobId} jobName={jobName} />
        </Box>
      </Modal>
    </div>
  );
}
