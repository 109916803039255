import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ScreenSelect from "./resume-screening-tab/ScreenSelect";
import ToBeReviewedTab from "./resume-screening-tab/ToBeReviewedTab";
import RejectedTab from "./resume-screening-tab/RejectedTab";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CandidateViewTab({}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { state } = useLocation();

  const jobId = state.jobId;
  return (
    <Box sx={{ width: "100%" }}>
      <h3>Screening</h3>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Screen Selected" className="fs-6" {...a11yProps(0)} />
          <Tab label="To be reviewed" className="fs-6" {...a11yProps(1)} />

          <Tab label="Rejected" className="fs-6" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ScreenSelect jobId={jobId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ToBeReviewedTab jobId={jobId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RejectedTab jobId={jobId} />
      </TabPanel>
    </Box>
  );
}
