import { API_URL } from "./env";
import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "../css/LoginComponent.css";
import rectangle from "../images/rectangle.png";
import { authContext } from "../hooks/authContext";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
var query = require("india-pincode-search");

// Companyvalidate
var CompanyEmailValidator = require("company-email-validator");

// const data=query.search('638103');

export default function RegistrationComponent() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [temp_password, setTemp_password] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyname] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pincode, setPincode] = useState("");
  var [city, setCity] = useState("");
  var [state, setState] = useState("");
  var [country, setCountry] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const { dispatch } = useContext(authContext);

  const handlePhoneNumberChange = (value) => {
    setPhonenumber(value);
  };
  const data = query.search(pincode);

  if (data.length !== 0) {
    city = data[0].city;
    state = data[0].state;
    country = "INDIA";
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    if (!username.trim()) {
      setErrorMessage("Username is required");
      return false;
    }

    if (!CompanyEmailValidator.isCompanyEmail(email)) {
      setErrorMessage(
        "Email Invalid - Only organization email must be entered"
      );
      return false;
    }

    if (!email.trim()) {
      setErrorMessage("Email is required");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address");
      return false;
    }

    if (!temp_password.trim()) {
      setErrorMessage("Password is required");
      return false;
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordRegex.test(temp_password)) {
      setErrorMessage(
        "Password must have at least 6 characters, one uppercase letter, and one number"
      );
      return false;
    }

    if (!password.trim()) {
      setErrorMessage("Confirmation Password is required");
      return false;
    }

    if (password !== temp_password) {
      setErrorMessage("Password and Confirmation Password do not match");
      return false;
    }

    if (!companyName.trim()) {
      setErrorMessage("Company name is required");
      return false;
    }

    if (companyName.length <= 3) {
      setErrorMessage("Company name should contain atleast 4 letters");
      return false;
    }

    if (!address.trim()) {
      setErrorMessage("Address is required");
      return false;
    }

    if (!phoneNumber.trim()) {
      setErrorMessage("Phone number is required");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const collectData = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    const user = {
      username,
      email,
      password,
      companyName,
      address,
      phoneNumber,
      pincode,
      city,
      state,
      country,
    };
    try {
      const response = await axios.post(
        `${API_URL}/get-otp?email=${email}&username=${username}`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/otp", { state: { email, username, user } });
      const result = response.data;
      console.log(result);
      setUsername("");
      setEmail("");
      setTemp_password("");
      setCompanyname("");
      setPassword("");
      setAddress("");
      setPhonenumber("");
      setErrorMessage("");
      setCity("");
      setState("");
      setCountry("");
      setPincode("");
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        setErrorMessage("No response received from the server");
      } else {
        setErrorMessage("Error setting up the request:", error.message);
      }
    }
  };

  return (
    <div className="container-fluid primary-color">
      <section className="row">
        <div className=" col-md-6 d-flex justify-content-center">
          <div className="shadow px-4 rounded-3 w-75">
            <div className="text-left">
              <h4 className="mb-3">Register Now</h4>
              {/* <h6>All Feilds are compulsory</h6> */}
              <input
                type="text"
                className="form-control mb-4"
                placeholder="User name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="email"
                className="form-control mb-4"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className=" position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control mb-3 "
                  placeholder="Password"
                  value={temp_password}
                  onChange={(e) => setTemp_password(e.target.value)}
                  required
                />
                <span
                  className="password-toggle-icon position-absolute top-50 end-0 translate-middle-y px-2 pt-1"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <ion-icon name="eye-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  )}
                </span>
              </div>
              <div className=" position-relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control mb-3 "
                  placeholder="Confirm Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="password-toggle-icon position-absolute top-50 end-0 translate-middle-y px-2 pt-1"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <ion-icon name="eye-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  )}
                </span>
              </div>
              <input
                type="text"
                className="form-control mb-4"
                placeholder="Company name"
                value={companyName}
                onChange={(e) => setCompanyname(e.target.value)}
                required
              />
              <textarea
                type="text"
                className="form-control mb-4"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <div className="row">
                <div className=" col-6">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    required
                  />
                </div>

                <div className=" col-6">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="rounded">
                <PhoneInput
                  style={{ borderColor: "#6F4DF5" }}
                  className="input-group mb-3 phone"
                  inputStyle={{ borderRadius: "5px", borderColor: "#6F4DF5" }}
                  buttonStyle={{
                    borderRadius: "5px",
                    backgroundColor: "white",
                    borderColor: "#6F4DF5",
                  }}
                  country="in"
                  value={phoneNumber}
                  //onChange={(e) => setPhonenumber(e.target.value)}
                  onChange={handlePhoneNumberChange}
                  // onChange={(phone) => setUserData({ ...userData, phone })}
                  placeholder="Phone Number.." // Add placeholder here
                />
              </div>
              {errorMessage && (
                <div className="mb-3" style={{ color: "red" }}>
                  {errorMessage}
                </div>
              )}
              <form onSubmit={collectData}>
                <button
                  style={{ "background-color": "#6F4DF5" }}
                  className="button rounded-2 w-100 text-white p-1"
                  type="submit"
                >
                  SIGN UP
                </button>
              </form>
              Already have an account?{" "}
              <a href="/" className="link">
                <Link to="/login">Sign In</Link>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6 d-flex justify-content-end">
          <div>
            <img
              style={{ height: "100%", width: "100%" }}
              src={rectangle}
              alt="altimage"
              className="vh-100 img-responsive"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
