import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ImFolderDownload } from "react-icons/im";
import { Link } from "react-router-dom";
import { authContext } from "../../hooks/authContext";

import ActionButton from "../ActionButton";
import { API_URL } from "../env";
import ViewDetailsModal from "../ViewDetailsModal";

function RejectedTab({ jobId }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [show, setShow] = useState(false);
  const selectHandlerFunction = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setShow(true);
  };
  const getData = async () => {
    try {
      const results = await axios.get(
        `${API_URL}/candidate/get-candidates/${jobId}?status=rejected&stage=screening`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setTableData(results.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCandidates = async (status) => {
    const selectedIds = selectedRows.map((obj) => obj._id);

    try {
      const results = await axios.put(
        `${API_URL}/candidate/modify-candidates?status=${status}&stage=screening`,
        {
          selectedIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(results.data.message);
      setShow(false);
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const { user } = useContext(authContext);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "Candidate Name",
      selector: (row) => {
        return (
          <h6 className="text-bold text-primary text-wrap">
            {row.resume.full_name[0].toLowerCase()}
          </h6>
        );
      },
    },
    {
      name: "Candidate Title",
      selector: (row) => {
        return <h6 className="text-primary text-wrap">{row.jobTitle}</h6>;
      },
    },

    {
      name: "Experience",
      selector: (row) => {
        return (
          <h6 className="text-primary">
            {row.resume.total_experience ? row.resume.total_experience : 0}
          </h6>
        );
      },
    },

    {
      name: "Uploaded Date",
      selector: (row) => {
        return <h6 className="text-primary">{row.date.split("T")[0]}</h6>;
      },
    },
    {
      name: "Reason",
      selector: (row) => {
        return <ViewDetailsModal details={row} />;
      },
    },

    {
      name: "",
      selector: (row) => {
        return (
          <div className="">
            <ActionButton
              getData={getData}
              id={row._id}
              name="screenreject"
              downLoadCv={<button className="">Download CV</button>}
              accept={
                <div className="">
                  <button
                    className="text-success"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <h6>Accept</h6>
                  </button>
                </div>
              }
              review={
                <div>
                  <button
                    style={{
                      textDecoration: "none",
                      color: "orange",
                    }}
                  >
                    <h6>Review</h6>
                  </button>
                </div>
              }
            />
          </div>
        );
      },
    },
  ];

  const customStyles = {
    table: {},
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "rgb(156, 164, 209)",
        fontSize: "14px",
        color: "rgb(63, 76, 147)",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        selectableRows={true}
        onSelectedRowsChange={selectHandlerFunction}
      />
      {selectedRows.length > 0 && show && (
        <div>
          <div className="my-4">
            <div className="my-2">
              <button
                className="btn btn-success"
                onClick={() => handleCandidates("selected")}
              >
                <b>Accept Selected Candidates</b>
              </button>
            </div>

            <div>
              <button
                style={{
                  backgroundColor: "orange",
                  color: "white",
                }}
                className="btn"
                onClick={() => handleCandidates("reviewed")}
              >
                <b>Review Selected Candidates</b>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RejectedTab;
